import React from 'react';
import Text from './fields/Text';
import { formatCurrency, round } from '../../../Components/Util';
import SelectField from './fields/Select';

function CyberHygiene(props) {
    const data = props.data;
    const errors = props.errors;
    const handleChange = props.handleChange;
    const job_classes = [
        { value: 'Clinical Staff', label: 'Clinical Staff' },
        { value: 'Clerical Staff', label: 'Clerical Staff' },
        { value: 'Network Engineer', label: 'Network Engineer' },
        { value: 'InfoSec Engineer', label: 'InfoSec Engineer' },
        { value: 'Biomedical Engineer', label: 'Biomedical Engineer' }
    ];
    const ramp ={
        yr1: 0.25,
        yr2: 0.85,
        yr3: 1,
        yr4: 1,
        yr5: 1
    }
    const get_salary = (jc) =>{
        let val =0;
        if (jc==='Clinical Staff') {
            val= parseFloat(data.step12__salary_clinical_staff)/50/40;
        } else if(jc==='Clerical Staff'){
            val=  parseFloat(data.step12__salary_clerical_staff)/50/40;
        } else if(jc==='Network Engineer'){
            val=  parseFloat(data.step12__salary_network_engineer)/50/40;
        } else if(jc==='InfoSec Engineer'){
            val=  parseFloat(data.step12__salary_infosec_engineer)/50/40;
        } else if(jc==='Biomedical Engineer'){
            val=  parseFloat(data.step12__salary_biomedical_engineer)/50/40;
        }
        
        return val;
    }

    const growth_devices_yr1 = parseFloat(data.step12__number_of_devices_across_facilities) * (1 + parseFloat(data.step12__cagr) / 100);
    const growth_owned_devices_yr1 = Math.round(growth_devices_yr1 * (1 - parseFloat(data.step12__per_rental_devices) / 100));
    const new_device_types_devices_yr1 = Math.ceil((parseFloat(data.step12__number_of_installed) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr1);
    
    const research_vul_effort_current_yr1 = (round(parseFloat(data.step14__research_vul_effort) * new_device_types_devices_yr1 / 60, 1));
    const corr_vul_effort_current_yr1 = (round(parseFloat(data.step14__corr_vul_effort) * new_device_types_devices_yr1 / 60, 1));
    const corr_ctx_effort_current_yr1 = (round(parseFloat(data.step14__corr_ctx_effort) * new_device_types_devices_yr1 / 60, 1));
    const input_risk_effort_current_yr1 = (round(parseFloat(data.step14__input_risk_effort) * new_device_types_devices_yr1 / 60, 1));
    const define_exc_effort_current_yr1 = (round(parseFloat(data.step14__define_exc_effort) * new_device_types_devices_yr1 / 60, 1));
    const resolve_effort_current_yr1 = (round(parseFloat(data.step14__resolve_effort) * new_device_types_devices_yr1 / 60, 1));

    const current_yr1 = Math.ceil(research_vul_effort_current_yr1*get_salary(data.step14__research_vul_job_class)
    + corr_vul_effort_current_yr1* get_salary(data.step14__corr_vul_job_class)
    + corr_ctx_effort_current_yr1*get_salary(data.step14__corr_ctx_job_class)
    + input_risk_effort_current_yr1*get_salary(data.step14__input_risk_job_class)
    + define_exc_effort_current_yr1*get_salary(data.step14__define_exc_job_class)
    + resolve_effort_current_yr1*get_salary(data.step14__resolve_job_class));



    const research_vul_effort_current_yr2 = round(research_vul_effort_current_yr1 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const corr_vul_effort_current_yr2 = round(corr_vul_effort_current_yr1 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const corr_ctx_effort_current_yr2 = round(corr_ctx_effort_current_yr1 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const input_risk_effort_current_yr2 = round(input_risk_effort_current_yr1 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const define_exc_effort_current_yr2 = round(define_exc_effort_current_yr1 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const resolve_effort_current_yr2 = round(resolve_effort_current_yr1 * (1 + parseFloat(data.step12__cagr) / 100), 1);

    const current_yr2 = Math.ceil(research_vul_effort_current_yr2*get_salary(data.step14__research_vul_job_class)
    + corr_vul_effort_current_yr2* get_salary(data.step14__corr_vul_job_class)
    + corr_ctx_effort_current_yr2*get_salary(data.step14__corr_ctx_job_class)
    + input_risk_effort_current_yr2*get_salary(data.step14__input_risk_job_class)
    + define_exc_effort_current_yr2*get_salary(data.step14__define_exc_job_class)
    + resolve_effort_current_yr2*get_salary(data.step14__resolve_job_class));


    const research_vul_effort_current_yr3 = round(research_vul_effort_current_yr2 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const corr_vul_effort_current_yr3 = round(corr_vul_effort_current_yr2 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const corr_ctx_effort_current_yr3 = round(corr_ctx_effort_current_yr2 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const input_risk_effort_current_yr3 = round(input_risk_effort_current_yr2 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const define_exc_effort_current_yr3 = round(define_exc_effort_current_yr2 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const resolve_effort_current_yr3 = round(resolve_effort_current_yr2 * (1 + parseFloat(data.step12__cagr) / 100), 1);

    const current_yr3 = Math.ceil(research_vul_effort_current_yr3*get_salary(data.step14__research_vul_job_class)
    + corr_vul_effort_current_yr3* get_salary(data.step14__corr_vul_job_class)
    + corr_ctx_effort_current_yr3*get_salary(data.step14__corr_ctx_job_class)
    + input_risk_effort_current_yr3*get_salary(data.step14__input_risk_job_class)
    + define_exc_effort_current_yr3*get_salary(data.step14__define_exc_job_class)
    + resolve_effort_current_yr3*get_salary(data.step14__resolve_job_class));

    const research_vul_effort_current_yr4 = round(research_vul_effort_current_yr3 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const corr_vul_effort_current_yr4 = round(corr_vul_effort_current_yr3 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const corr_ctx_effort_current_yr4 = round(corr_ctx_effort_current_yr3 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const input_risk_effort_current_yr4 = round(input_risk_effort_current_yr3 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const define_exc_effort_current_yr4 = round(define_exc_effort_current_yr3 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const resolve_effort_current_yr4 = round(resolve_effort_current_yr3 * (1 + parseFloat(data.step12__cagr) / 100), 1);

    const current_yr4 = Math.ceil(research_vul_effort_current_yr4*get_salary(data.step14__research_vul_job_class)
    + corr_vul_effort_current_yr4* get_salary(data.step14__corr_vul_job_class)
    + corr_ctx_effort_current_yr4*get_salary(data.step14__corr_ctx_job_class)
    + input_risk_effort_current_yr4*get_salary(data.step14__input_risk_job_class)
    + define_exc_effort_current_yr4*get_salary(data.step14__define_exc_job_class)
    + resolve_effort_current_yr4*get_salary(data.step14__resolve_job_class));

    const research_vul_effort_current_yr5 = round(research_vul_effort_current_yr4 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const corr_vul_effort_current_yr5 = round(corr_vul_effort_current_yr4 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const corr_ctx_effort_current_yr5 = round(corr_ctx_effort_current_yr4 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const input_risk_effort_current_yr5 = round(input_risk_effort_current_yr4 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const define_exc_effort_current_yr5 = round(define_exc_effort_current_yr4 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const resolve_effort_current_yr5 = round(resolve_effort_current_yr4 * (1 + parseFloat(data.step12__cagr) / 100), 1);

    const current_yr5 = Math.ceil(research_vul_effort_current_yr5*get_salary(data.step14__research_vul_job_class)
    + corr_vul_effort_current_yr5* get_salary(data.step14__corr_vul_job_class)
    + corr_ctx_effort_current_yr5*get_salary(data.step14__corr_ctx_job_class)
    + input_risk_effort_current_yr5*get_salary(data.step14__input_risk_job_class)
    + define_exc_effort_current_yr5*get_salary(data.step14__define_exc_job_class)
    + resolve_effort_current_yr5*get_salary(data.step14__resolve_job_class));
    
    const research_vul_effort_proposed_yr1= round(research_vul_effort_current_yr1-(research_vul_effort_current_yr1*parseFloat(data.step14__research_vul_impact)/100*ramp.yr1),4);
    const corr_vul_effort_proposed_yr1= round(corr_vul_effort_current_yr1-(corr_vul_effort_current_yr1*parseFloat(data.step14__corr_vul_impact)/100*ramp.yr1),4);
    const corr_ctx_effort_proposed_yr1= round(corr_ctx_effort_current_yr1-(corr_ctx_effort_current_yr1*parseFloat(data.step14__corr_ctx_impact)/100*ramp.yr1),4);
    const input_risk_effort_proposed_yr1= round(input_risk_effort_current_yr1-(input_risk_effort_current_yr1*parseFloat(data.step14__input_risk_impact)/100*ramp.yr1),4);
    const define_exc_effort_proposed_yr1= round(define_exc_effort_current_yr1-(define_exc_effort_current_yr1*parseFloat(data.step14__define_exc_impact)/100*ramp.yr1),4);
    const resolve_effort_proposed_yr1= round(resolve_effort_current_yr1-(resolve_effort_current_yr1*parseFloat(data.step14__resolve_impact)/100*ramp.yr1),4);
    
  const proposed_yr1 = Math.ceil(research_vul_effort_proposed_yr1*get_salary(data.step14__research_vul_job_class)
    + corr_vul_effort_proposed_yr1* get_salary(data.step14__corr_vul_job_class)
    + corr_ctx_effort_proposed_yr1*get_salary(data.step14__corr_ctx_job_class)
    + input_risk_effort_proposed_yr1*get_salary(data.step14__input_risk_job_class)
    + define_exc_effort_proposed_yr1*get_salary(data.step14__define_exc_job_class)
    + resolve_effort_proposed_yr1*get_salary(data.step14__resolve_job_class));
    
    const research_vul_effort_proposed_yr2= round(research_vul_effort_current_yr2-(research_vul_effort_current_yr2*parseFloat(data.step14__research_vul_impact)/100*ramp.yr2),4);
    const corr_vul_effort_proposed_yr2= round(corr_vul_effort_current_yr2-(corr_vul_effort_current_yr2*parseFloat(data.step14__corr_vul_impact)/100*ramp.yr2),4);
    const corr_ctx_effort_proposed_yr2= round(corr_ctx_effort_current_yr2-(corr_ctx_effort_current_yr2*parseFloat(data.step14__corr_ctx_impact)/100*ramp.yr2),4);
    const input_risk_effort_proposed_yr2= round(input_risk_effort_current_yr2-(input_risk_effort_current_yr2*parseFloat(data.step14__input_risk_impact)/100*ramp.yr2),4);
    const define_exc_effort_proposed_yr2= round(define_exc_effort_current_yr2-(define_exc_effort_current_yr2*parseFloat(data.step14__define_exc_impact)/100*ramp.yr2),4);
    const resolve_effort_proposed_yr2= round(resolve_effort_current_yr2-(resolve_effort_current_yr2*parseFloat(data.step14__resolve_impact)/100*ramp.yr2),4);


    const proposed_yr2 = Math.ceil(research_vul_effort_proposed_yr2*get_salary(data.step14__research_vul_job_class)
    + corr_vul_effort_proposed_yr2* get_salary(data.step14__corr_vul_job_class)
    + corr_ctx_effort_proposed_yr2*get_salary(data.step14__corr_ctx_job_class)
    + input_risk_effort_proposed_yr2*get_salary(data.step14__input_risk_job_class)
    + define_exc_effort_proposed_yr2*get_salary(data.step14__define_exc_job_class)
    + resolve_effort_proposed_yr2*get_salary(data.step14__resolve_job_class));

    const research_vul_effort_proposed_yr3= round(research_vul_effort_current_yr3-(research_vul_effort_current_yr3*parseFloat(data.step14__research_vul_impact)/100*ramp.yr3),4);
    const corr_vul_effort_proposed_yr3= round(corr_vul_effort_current_yr3-(corr_vul_effort_current_yr3*parseFloat(data.step14__corr_vul_impact)/100*ramp.yr3),4);
    const corr_ctx_effort_proposed_yr3= round(corr_ctx_effort_current_yr3-(corr_ctx_effort_current_yr3*parseFloat(data.step14__corr_ctx_impact)/100*ramp.yr3),4);
    const input_risk_effort_proposed_yr3= round(input_risk_effort_current_yr3-(input_risk_effort_current_yr3*parseFloat(data.step14__input_risk_impact)/100*ramp.yr3),4);
    const define_exc_effort_proposed_yr3= round(define_exc_effort_current_yr3-(define_exc_effort_current_yr3*parseFloat(data.step14__define_exc_impact)/100*ramp.yr3),4);
    const resolve_effort_proposed_yr3= round(resolve_effort_current_yr3-(resolve_effort_current_yr3*parseFloat(data.step14__resolve_impact)/100*ramp.yr3),4);


    const proposed_yr3 = Math.ceil(research_vul_effort_proposed_yr3*get_salary(data.step14__research_vul_job_class)
    + corr_vul_effort_proposed_yr3* get_salary(data.step14__corr_vul_job_class)
    + corr_ctx_effort_proposed_yr3*get_salary(data.step14__corr_ctx_job_class)
    + input_risk_effort_proposed_yr3*get_salary(data.step14__input_risk_job_class)
    + define_exc_effort_proposed_yr3*get_salary(data.step14__define_exc_job_class)
    + resolve_effort_proposed_yr3*get_salary(data.step14__resolve_job_class));

    const research_vul_effort_proposed_yr4= round(research_vul_effort_current_yr4-(research_vul_effort_current_yr4*parseFloat(data.step14__research_vul_impact)/100*ramp.yr4),4);
    const corr_vul_effort_proposed_yr4= round(corr_vul_effort_current_yr4-(corr_vul_effort_current_yr4*parseFloat(data.step14__corr_vul_impact)/100*ramp.yr4),4);
    const corr_ctx_effort_proposed_yr4= round(corr_ctx_effort_current_yr4-(corr_ctx_effort_current_yr4*parseFloat(data.step14__corr_ctx_impact)/100*ramp.yr4),4);
    const input_risk_effort_proposed_yr4= round(input_risk_effort_current_yr4-(input_risk_effort_current_yr4*parseFloat(data.step14__input_risk_impact)/100*ramp.yr4),4);
    const define_exc_effort_proposed_yr4= round(define_exc_effort_current_yr4-(define_exc_effort_current_yr4*parseFloat(data.step14__define_exc_impact)/100*ramp.yr4),4);
    const resolve_effort_proposed_yr4= round(resolve_effort_current_yr4-(resolve_effort_current_yr4*parseFloat(data.step14__resolve_impact)/100*ramp.yr4),4);

    const proposed_yr4 = Math.ceil(research_vul_effort_proposed_yr4*get_salary(data.step14__research_vul_job_class)
    + corr_vul_effort_proposed_yr4* get_salary(data.step14__corr_vul_job_class)
    + corr_ctx_effort_proposed_yr4*get_salary(data.step14__corr_ctx_job_class)
    + input_risk_effort_proposed_yr4*get_salary(data.step14__input_risk_job_class)
    + define_exc_effort_proposed_yr4*get_salary(data.step14__define_exc_job_class)
    + resolve_effort_proposed_yr4*get_salary(data.step14__resolve_job_class));

    const research_vul_effort_proposed_yr5= round(research_vul_effort_current_yr5-(research_vul_effort_current_yr5*parseFloat(data.step14__research_vul_impact)/100*ramp.yr5),4);
    const corr_vul_effort_proposed_yr5= round(corr_vul_effort_current_yr5-(corr_vul_effort_current_yr5*parseFloat(data.step14__corr_vul_impact)/100*ramp.yr5),4);
    const corr_ctx_effort_proposed_yr5= round(corr_ctx_effort_current_yr5-(corr_ctx_effort_current_yr5*parseFloat(data.step14__corr_ctx_impact)/100*ramp.yr5),4);
    const input_risk_effort_proposed_yr5= round(input_risk_effort_current_yr5-(input_risk_effort_current_yr5*parseFloat(data.step14__input_risk_impact)/100*ramp.yr5),4);
    const define_exc_effort_proposed_yr5= round(define_exc_effort_current_yr5-(define_exc_effort_current_yr5*parseFloat(data.step14__define_exc_impact)/100*ramp.yr5),4);
    const resolve_effort_proposed_yr5= round(resolve_effort_current_yr5-(resolve_effort_current_yr5*parseFloat(data.step14__resolve_impact)/100*ramp.yr5),4);


    const proposed_yr5 = Math.ceil(research_vul_effort_proposed_yr5*get_salary(data.step14__research_vul_job_class)
    + corr_vul_effort_proposed_yr5* get_salary(data.step14__corr_vul_job_class)
    + corr_ctx_effort_proposed_yr5*get_salary(data.step14__corr_ctx_job_class)
    + input_risk_effort_proposed_yr5*get_salary(data.step14__input_risk_job_class)
    + define_exc_effort_proposed_yr5*get_salary(data.step14__define_exc_job_class)
    + resolve_effort_proposed_yr5*get_salary(data.step14__resolve_job_class));

    const saving_yr1=current_yr1-proposed_yr1;
    const saving_yr2=current_yr2-proposed_yr2;
    const saving_yr3=current_yr3-proposed_yr3;
    const saving_yr4=current_yr4-proposed_yr4;
    const saving_yr5=current_yr5-proposed_yr5;

    const step14__total = saving_yr1;
  

    const next = () => {
        props.next({ step14__total: step14__total , step14__yr1:saving_yr1, step14__yr2:saving_yr2 , step14__yr3:saving_yr3 , step14__yr4:saving_yr4 , step14__yr5:saving_yr5});

    }
    const previous = () => {
        props.previous();
    }
    return (
        <div className='container'>
            <div className='row mt-3'>
            <div className='col-10' >
                <h4>Cyber Hygiene Labor</h4>
                </div>
                <div className='col-2' >
                <SelectField
                    fieldColor="orange"
                    fieldName="step14__mvr"
                    value={(data && data["step14__mvr"] && props.mvrs.find(item => item.value === data["step14__mvr"])) || null}
                    options={props.mvrs}
                    label="Months to Value Realization"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step14__mvr"]}
                />
                </div>
            </div>
            <div className='col-12' >
                    <div className='row mt-3' style={{ textAlign: "center", background: parseFloat(step14__total) > 0 ? "#4D9262" : "#cc0000", color: "#ffff", padding: "10px", borderRadius: "5px" }}>
                        <div style={{ textTransform: "uppercase", fontSize: "0.65em" }}>Cyber Hygiene Labor Expected Savings</div>
                        <div className='row' >
                            
                            <div className='col-12'>
                                <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>
                                    ${formatCurrency(
                                        parseFloat(step14__total), 0)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className='row mt-3 gx-5 '>
                <div className='col-12'>
                <div className='row mt-3'>
                            <div className='col-12'>
                            <table className='table'>
                                 <colgroup>
                                    <col  style={{width:"40%"}} />
                                    <col style={{width:"15%"}} />
                                    <col style={{width:"25%"}} />
                                    <col style={{width:"10%"}} />
                                    <col style={{width:"10%"}} />
                                </colgroup>
                                    <tbody>
                                    <tr>
                                        <td valign="bottom">Research vulnerabilities, OS context, network connectivity and enforcement state for one new device type</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step14__research_vul_effort"
                                                value={data["step14__research_vul_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step14__research_vul_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step14__research_vul_job_class"
                                            value={(data && data["step14__research_vul_job_class"] && job_classes.find(item => item.value === data["step14__research_vul_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step14__research_vul_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                            <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Volume (Year 1)</div>
                                            <div style={{ fontSize: "1.65em"}}>{formatCurrency(new_device_types_devices_yr1, 0)}</div>
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step14__research_vul_impact"
                                            value={data["step14__research_vul_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step14__research_vul_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>
                                        
                                    <tr>
                                        <td valign="bottom">Correlate vulnerabilities , OS knowledge, network connectivity and enforcement state into a "likelihood" score for one device type</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step14__corr_vul_effort"
                                                value={data["step14__corr_vul_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step14__corr_vul_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step14__corr_vul_job_class"
                                            value={(data && data["step14__corr_vul_job_class"] && job_classes.find(item => item.value === data["step14__corr_vul_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step14__corr_vul_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                            <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Volume (Year 1)</div>
                                            <div style={{ fontSize: "1.65em"}}>{formatCurrency(new_device_types_devices_yr1, 0)}</div>
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step14__corr_vul_impact"
                                            value={data["step14__corr_vul_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step14__corr_vul_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>      

                                    <tr>
                                        <td valign="bottom">Correlate clinical and business context into a "severity" score for one device type</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step14__corr_ctx_effort"
                                                value={data["step14__corr_ctx_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step14__corr_ctx_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step14__corr_ctx_job_class"
                                            value={(data && data["step14__corr_ctx_job_class"] && job_classes.find(item => item.value === data["step14__corr_ctx_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step14__corr_ctx_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                            <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Volume (Year 1)</div>
                                            <div style={{ fontSize: "1.65em"}}>{formatCurrency(new_device_types_devices_yr1, 0)}</div>
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step14__corr_ctx_impact"
                                            value={data["step14__corr_ctx_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step14__corr_ctx_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td valign="bottom">Input risk impact for managed devices into vulnerability management platform for one device type</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step14__input_risk_effort"
                                                value={data["step14__input_risk_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step14__input_risk_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step14__input_risk_job_class"
                                            value={(data && data["step14__input_risk_job_class"] && job_classes.find(item => item.value === data["step14__input_risk_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step14__input_risk_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                            <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Volume (Year 1)</div>
                                            <div style={{ fontSize: "1.65em"}}>{formatCurrency(new_device_types_devices_yr1, 0)}</div>
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step14__input_risk_impact"
                                            value={data["step14__input_risk_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step14__input_risk_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td valign="bottom">Determine and define which devices to include and exclude in scan</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step14__define_exc_effort"
                                                value={data["step14__define_exc_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step14__define_exc_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step14__define_exc_job_class"
                                            value={(data && data["step14__define_exc_job_class"] && job_classes.find(item => item.value === data["step14__define_exc_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step14__define_exc_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                            <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Volume (Year 1)</div>
                                            <div style={{ fontSize: "1.65em"}}>{formatCurrency(new_device_types_devices_yr1, 0)}</div>
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step14__define_exc_impact"
                                            value={data["step14__define_exc_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step14__define_exc_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td valign="bottom">Time needed to resolve damage from accidental scan of sensitive devices</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step14__resolve_effort"
                                                value={data["step14__resolve_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step14__resolve_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step14__resolve_job_class"
                                            value={(data && data["step14__resolve_job_class"] && job_classes.find(item => item.value === data["step14__resolve_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step14__resolve_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                            <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Volume (Year 1)</div>
                                            <div style={{ fontSize: "1.65em"}}>{formatCurrency(new_device_types_devices_yr1, 0)}</div>
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step14__resolve_impact"
                                            value={data["step14__resolve_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step14__resolve_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>  
                                    </tbody>
                                </table>
                            </div>
                        </div>               


                </div>

            </div>
            <div className='row mt-3 gx-5 '>
                <div className='col-12'>
                <div className='row mt-3'>
                            <div className='col-12'>
                            <table className='table'>  
                            <thead>
                                    <tr>
                                        <th scope="col">&nbsp;</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 1</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 2</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 3</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 4</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 5</th>
                                    </tr>
                                </thead>
                                    <tbody>
                                   
                                    <tr>
                                     
                                        <td>Current Spending Without Medigate</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(current_yr1, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(current_yr2, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(current_yr3, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(current_yr4, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(current_yr5, 2)}</td>
                                    </tr>
                                    
                                    <tr>
                                      
                                        <td>Proposed Spending With Medigate</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(proposed_yr1, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(proposed_yr2, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(proposed_yr3, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(proposed_yr4, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(proposed_yr5, 2)}</td>
                                    </tr>
                                   
                                    <tr>
                                  
                                        <td>Expected Savings With Medigate</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(saving_yr1, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(saving_yr2, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(saving_yr3, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(saving_yr4, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(saving_yr5, 2)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>               

                </div>
            </div>
            <div className='mt-3'>
                <div className="clearfix">
                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => previous()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                    <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => next()} ><i className="mdi mdi-skip-next " aria-hidden="true" ></i> Next</button>
                </div>
            </div>
        </div>
    )
}

export default CyberHygiene;