import React, { useEffect, useState } from 'react';
import SelectField from './fields/Select';
import { startLoading, stopLoading, formatCurrency } from '../../../Components/Util';
import MultySelectDropdown from '../../../Components/widgets/MultySelectDropdown';
import * as service from "../../../services";
import Donut from './fields/Donut';
import ROI from './fields/LineChart';
import { AccuracyLine } from './fields/AccuracyLine';
import AdditionalRevenueCahrt from './fields/AdditionalRevenueChart';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ROIPie } from './fields/ROIPie';
import { ROILine } from './fields/ROILine';

function Summary(props) {
    const data = props.data;
    const errors = props.errors;
    const handleChange = props.handleChange;
    const [loading, setLoading] = useState(true);
    const [summary, setSummary] = useState([]);
    const [outer, setOuter] = useState([]);
    const [inner, setInner] = useState([]);
    const [totals, setTotals] = useState([]);
    const [roi, setRoi] = useState([]);
    const [estimates, setEstimates] = useState({});
    const [revChart, setRevChart] = useState([]);
    const [revSeries, setRevSeries] = useState([]);
    const [breakEven, setBreakEven] = useState([]);
    const [sharePop, setSharePop] = useState(false);
    const [shareOptions, setShareOptions] = useState(undefined);
    const [sharedModelUsers, setSharedModelUsers] = useState([]);
    const [sharedModelUsersMsg, setSharedModelUsersMsg] = useState(undefined);

    const accuracies = [
        { value: '0%', label: '0%' },
        { value: '5%', label: '5%' },
        { value: '10%', label: '10%' },
        { value: '15%', label: '15%' },
        { value: '20%', label: '20%' },
        { value: '25%', label: '25%' },
        { value: '30%', label: '30%' },
        { value: '35%', label: '35%' },
        { value: '40%', label: '40%' },
        { value: '45%', label: '45%' },
        { value: '50%', label: '50%' },
        { value: '55%', label: '55%' },
        { value: '60%', label: '60%' },
        { value: '65%', label: '65%' },
        { value: '70%', label: '70%' },
        { value: '75%', label: '75%' },
        { value: '80%', label: '80%' },
        { value: '85%', label: '85%' },
        { value: '90%', label: '90%' },
        { value: '95%', label: '95%' },
        { value: '100%', label: '100%' },
    ];

    const load_data = (fn) => {

        startLoading();
        const adjustment = parseFloat(data.adjustment);

        const adj = adjustment / 100;
        let p1 = new Promise((resolve, reject) => {
            service.getBvaHcData("get_model_summary", { model_id: props.match.params.id }, (res2) => {
                let sumObj = {};
                setSummary(res2);
                if (res2) {
                    setInner(res2.filter(item => (item.item_type === "Recovered Revenues" || item.item_type === "Streamlined Maintenance" || item.item_type === "Cybersecurity Cost Avoidance" || item.item_type === "Device Lifecycle Savings") && item.item_label.includes("Total")).map(item => {

                        return { name: item.item_type, value: item.total };
                    }));
                    res2.filter(item => item.item_label.includes("Total")).forEach(item => {
                        if (item.item_type === "Recovered Revenues" || item.item_type === "Streamlined Maintenance" || item.item_type === "Cybersecurity Cost Avoidance" || item.item_type === "Device Lifecycle Savings") {
                            sumObj['yr1'] = item.yr1 + (sumObj['yr1'] || 0);
                            sumObj['yr2'] = item.yr2 + (sumObj['yr2'] || 0);
                            sumObj['yr3'] = item.yr3 + (sumObj['yr3'] || 0);
                            sumObj['yr4'] = item.yr4 + (sumObj['yr4'] || 0);
                            sumObj['yr5'] = item.yr5 + (sumObj['yr5'] || 0);
                            sumObj['cyr1'] = item.yr1 + (sumObj['cyr1'] || 0);
                            sumObj['cyr2'] = item.yr1 + item.yr2 + (sumObj['cyr2'] || 0);
                            sumObj['cyr3'] = item.yr1 + item.yr2 + item.yr3 + (sumObj['cyr3'] || 0);
                            sumObj['cyr4'] = item.yr1 + item.yr2 + item.yr3 + item.yr4 + (sumObj['cyr4'] || 0);
                            sumObj['cyr5'] = item.yr1 + item.yr2 + item.yr3 + item.yr4 + item.yr5 + (sumObj['cyr5'] || 0);
                            sumObj['total'] = item.total + (sumObj['total'] || 0);
                        } else {
                            sumObj['ccyr1'] = item.yr1 + (sumObj['ccyr1'] || 0);
                            sumObj['ccyr2'] = item.yr1 + item.yr2 + (sumObj['ccyr2'] || 0);
                            sumObj['ccyr3'] = item.yr1 + item.yr2 + item.yr3 + (sumObj['ccyr3'] || 0);
                            sumObj['ccyr4'] = item.yr1 + item.yr2 + item.yr3 + item.yr4 + (sumObj['ccyr4'] || 0);
                            sumObj['ccyr5'] = item.yr1 + item.yr2 + item.yr3 + item.yr4 + item.yr5 + (sumObj['ccyr5'] || 0);
                        }
                    });
                    let total_vals = [
                        { line_type: "Total ROI", yr1: sumObj.yr1, yr2: sumObj.yr2, yr3: sumObj.yr3, yr4: sumObj.yr4, yr5: sumObj.yr5, total: sumObj.total },
                        { line_type: "Cumulative Platform Fees", yr1: sumObj.ccyr1, yr2: sumObj.ccyr2, yr3: sumObj.ccyr3, yr4: sumObj.ccyr4, yr5: sumObj.ccyr5, total: 0 },
                        { line_type: "Cumulative ROI", yr1: sumObj.cyr1, yr2: sumObj.cyr2, yr3: sumObj.cyr3, yr4: sumObj.cyr4, yr5: sumObj.cyr5, total: 0 },
                        { line_type: "ROI", yr1: sumObj.cyr1 - sumObj.ccyr1, yr2: sumObj.cyr2 - sumObj.ccyr2, yr3: sumObj.cyr3 - sumObj.ccyr3, yr4: sumObj.cyr4 - sumObj.ccyr4, yr5: sumObj.cyr5 - sumObj.ccyr5, total: 0 }
                    ];
                    setTotals(total_vals);

                    setRoi([
                        {
                            "id": "ROI", "color": "hsl(350, 70%, 50%)", "data": [
                                { x: "Year 1", y: sumObj.cyr1 - sumObj.ccyr1 },
                                { x: "Year 2", y: sumObj.cyr2 - sumObj.ccyr2 },
                                { x: "Year 3", y: sumObj.cyr3 - sumObj.ccyr3 },
                                { x: "Year 4", y: sumObj.cyr4 - sumObj.ccyr4 },
                                { x: "Year 5", y: sumObj.cyr5 - sumObj.ccyr5 },
                            ]
                        },
                        {
                            "id": "Cumulative Platform Fees", "color": "hsl(61, 70%, 50%)", "data": [
                                { x: "Year 1", y: sumObj.ccyr1 },
                                { x: "Year 2", y: sumObj.ccyr2 },
                                { x: "Year 3", y: sumObj.ccyr3 },
                                { x: "Year 4", y: sumObj.ccyr4 },
                                { x: "Year 5", y: sumObj.ccyr5 },
                            ]
                        }
                    ]);
                    console.log(roi);
                    setOuter(res2.filter(item => (item.item_type === "Recovered Revenues" || item.item_type === "Streamlined Maintenance" || item.item_type === "Cybersecurity Cost Avoidance" || item.item_type === "Device Lifecycle Savings") && !item.item_label.includes("Total")).map((item, index) => {
                        let color = "hsl(" + 360 * index / 12 + ", 80%, 50%)";
                        return { id: item.item_label, label: item.item_label, value: item.total, "color": color };
                    }));

                    const annual_ord_value_full = total_vals.find(item => item.line_type === "Total ROI");
                    const annual_ord_cost = res2.find(item => item.item_type === "System Costs" && item.item_label.includes("Total"));

                    const additional_revenue_full = {
                        yr1: annual_ord_value_full.yr1 - annual_ord_cost.yr1,
                        yr2: annual_ord_value_full.yr2 - annual_ord_cost.yr2,
                        yr3: annual_ord_value_full.yr3 - annual_ord_cost.yr3,
                        yr4: annual_ord_value_full.yr4 - annual_ord_cost.yr4,
                        yr5: annual_ord_value_full.yr5 - annual_ord_cost.yr5,
                        total: annual_ord_value_full.total - annual_ord_cost.total
                    };
                    const accuracy_of_estimate = parseFloat(data.accuracy_of_estimate) / 100;
                    console.log(accuracy_of_estimate, adj);
                    const annual_ord_value_adjusted = {
                        yr1: annual_ord_value_full.yr1 * accuracy_of_estimate * adj,
                        yr2: annual_ord_value_full.yr2 * accuracy_of_estimate * adj,
                        yr3: annual_ord_value_full.yr3 * accuracy_of_estimate * adj,
                        yr4: annual_ord_value_full.yr4 * accuracy_of_estimate * adj,
                        yr5: annual_ord_value_full.yr5 * accuracy_of_estimate * adj,
                        total: annual_ord_value_full.total * accuracy_of_estimate * adj
                    };

                    const additional_revenue_adjusted = {
                        yr1: annual_ord_value_adjusted.yr1 - annual_ord_cost.yr1,
                        yr2: annual_ord_value_adjusted.yr2 - annual_ord_cost.yr2,
                        yr3: annual_ord_value_adjusted.yr3 - annual_ord_cost.yr3,
                        yr4: annual_ord_value_adjusted.yr4 - annual_ord_cost.yr4,
                        yr5: annual_ord_value_adjusted.yr5 - annual_ord_cost.yr5,
                        total: annual_ord_value_adjusted.total - annual_ord_cost.total
                    };

                    const annual_cyber = res2.find(item => item.item_type === "Cybersecurity Cost Avoidance" && item.item_label.includes("Total"));
                    const annual_ord_value_full_ex = {
                        yr1: annual_ord_value_full.yr1 - annual_cyber.yr1,
                        yr2: annual_ord_value_full.yr2 - annual_cyber.yr2,
                        yr3: annual_ord_value_full.yr3 - annual_cyber.yr3,
                        yr4: annual_ord_value_full.yr4 - annual_cyber.yr4,
                        yr5: annual_ord_value_full.yr5 - annual_cyber.yr5,
                        total: annual_ord_value_full.total - annual_cyber.total
                    };
                    const additional_revenue_full_ex = {
                        yr1: annual_ord_value_full_ex.yr1 - annual_ord_cost.yr1,
                        yr2: annual_ord_value_full_ex.yr2 - annual_ord_cost.yr2,
                        yr3: annual_ord_value_full_ex.yr3 - annual_ord_cost.yr3,
                        yr4: annual_ord_value_full_ex.yr4 - annual_ord_cost.yr4,
                        yr5: annual_ord_value_full_ex.yr5 - annual_ord_cost.yr5,
                        total: annual_ord_value_full_ex.total - annual_ord_cost.total
                    };



                    const annual_ord_value_adjusted_ex = {
                        yr1: annual_ord_value_full_ex.yr1 * accuracy_of_estimate * adj,
                        yr2: annual_ord_value_full_ex.yr2 * accuracy_of_estimate * adj,
                        yr3: annual_ord_value_full_ex.yr3 * accuracy_of_estimate * adj,
                        yr4: annual_ord_value_full_ex.yr4 * accuracy_of_estimate * adj,
                        yr5: annual_ord_value_full_ex.yr5 * accuracy_of_estimate * adj,
                        total: annual_ord_value_full_ex.total * accuracy_of_estimate * adj
                    };
                    const additional_revenue_adjusted_ex = {
                        yr1: annual_ord_value_adjusted_ex.yr1 - annual_ord_cost.yr1,
                        yr2: annual_ord_value_adjusted_ex.yr2 - annual_ord_cost.yr2,
                        yr3: annual_ord_value_adjusted_ex.yr3 - annual_ord_cost.yr3,
                        yr4: annual_ord_value_adjusted_ex.yr4 - annual_ord_cost.yr4,
                        yr5: annual_ord_value_adjusted_ex.yr5 - annual_ord_cost.yr5,
                        total: annual_ord_value_adjusted_ex.total - annual_ord_cost.total
                    };
                    setEstimates({
                        annual_ord_cost,
                        annual_ord_value_full,
                        annual_ord_value_adjusted,
                        additional_revenue_full,
                        additional_revenue_adjusted,
                        annual_ord_value_full_ex,
                        annual_cyber,
                        additional_revenue_full_ex,
                        annual_ord_value_adjusted_ex,
                        additional_revenue_adjusted_ex
                    });

                    const getYData = (accuracy) => {
                        const yr1 = annual_ord_value_full.yr1 * accuracy - annual_ord_cost.yr1;
                        const yr2 = annual_ord_value_full.yr2 * accuracy - annual_ord_cost.yr2 + yr1;
                        const yr3 = annual_ord_value_full.yr3 * accuracy - annual_ord_cost.yr3 + yr2;
                        const yr4 = annual_ord_value_full.yr4 * accuracy - annual_ord_cost.yr4 + yr3;
                        const yr5 = annual_ord_value_full.yr5 * accuracy - annual_ord_cost.yr5 + yr4;
                        return [
                            { x: "Year 1", y: yr1 },
                            { x: "Year 2", y: yr2 },
                            { x: "Year 3", y: yr3 },
                            { x: "Year 4", y: yr4 },
                            { x: "Year 5", y: yr5 }
                        ];
                    }
                    let revData = [];

                    if (accuracy_of_estimate < 0.25) {
                        revData.push({ id: accuracy_of_estimate * 100 + "%", color: "hsl(332, 70%, 50%)", data: getYData(accuracy_of_estimate) });
                    }
                    revData.push({ id: "25%", color: "hsl(165, 70%, 50%)", data: getYData(0.25) });

                    if (accuracy_of_estimate < 0.5 && accuracy_of_estimate > 0.25) {
                        revData.push({ id: accuracy_of_estimate * 100 + "%", color: "hsl(332, 70%, 50%)", data: getYData(accuracy_of_estimate) });
                    }
                    revData.push({ id: "50%", color: "hsl(316, 70%, 50%)", data: getYData(0.5) });
                    if (accuracy_of_estimate < 0.7 && accuracy_of_estimate > 0.5) {
                        revData.push({ id: accuracy_of_estimate * 100 + "%", color: "hsl(332, 70%, 50%)", data: getYData(accuracy_of_estimate) });
                    }
                    revData.push({ id: "75%", color: "hsl(291, 70%, 50%)", data: getYData(0.75) });
                    if (accuracy_of_estimate < 1 && accuracy_of_estimate > 0.75) {
                        revData.push({ id: accuracy_of_estimate * 100 + "%", color: "hsl(332, 70%, 50%)", data: getYData(accuracy_of_estimate) });
                    }
                    revData.push({ id: "100%", color: "hsl(287, 70%, 50%)", data: getYData(1) });
                    setRevChart(revData);
                    console.log(revData);
                }
            });
            resolve("");
        });
        let p2 = new Promise((resolve, reject) => {
            service.getLookups(res => {
                setShareOptions(res.users_share_hc);
                resolve("");
            });
        });
        let p3 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.id) > 0) {
                service.getBvaHcData("get-shared-with", { id: props.match.params.id }, (res) => {
                    setSharedModelUsers(res);
                    resolve("");
                });
            } else resolve("");
        });

        Promise.all([p1, p2, p3])
            .then(values => {

                get_break_even(() => {
                    stopLoading();

                    fn();
                    console.log("breakeven loaded");

                });
            })
            .catch(error => {
                stopLoading();

                console.log(error.message);
                fn();
            });
    }
    const get_break_even = (fn) => {
        //const accuracy = parseFloat(data.accuracy_of_estimate ? data.accuracy_of_estimate.replace('%', '') : '75');

        service.getBvaHcData("get_break_even", { model_id: props.match.params.id }, (res2) => {
            if (res2 && res2.length === 1) {
                setBreakEven(res2[0]);
                fn();
            }
        });
    }

    useEffect(() => {
        setLoading(true);
        load_data(() => {
            stopLoading();
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (!loading) {
            const adjustment = parseFloat(data.adjustment ? data.adjustment.replace('%', '') : '100');
            service.getBvaHcData("set_adjustment", { model_id: props.match.params.id, adjustment: adjustment }, (res2) => {
                load_data(() => {
                    stopLoading();
                });
            });
        }
    }, [data.adjustment]);
    useEffect(() => {
        if (!loading) {
            const accuracy = parseFloat(data.accuracy_of_estimate ? data.accuracy_of_estimate.replace('%', '') : '75');
            service.getBvaHcData("set_break_even", { model_id: props.match.params.id, accuracy_of_estimate: accuracy }, (res2) => {
                load_data(() => {

                    stopLoading();
                });
            });
        }
    }, [data.accuracy_of_estimate]);
    const saveSharedUsers = () => {
        if (!sharedModelUsers || sharedModelUsers.length <= 0) {
            setSharedModelUsersMsg('<div class="validation-error">Please select Users.</div>');
        } else {
            setSharedModelUsersMsg(undefined)
            let payload = JSON.parse(JSON.stringify(sharedModelUsers));
            payload = payload.map(item => { return { id: item.value } })
            startLoading();
            service.upsertSharedHCModelUsers(props.match.params.id, payload, res => {
                stopLoading();
                setSharedModelUsersMsg('<div class="validation-success">Saved successfully.</div>');
                setTimeout(() => {
                    setSharedModelUsersMsg();
                }, 1000)
            })
        }
    }
    const downloadPPTX = () => {
        service.downloadHCPPTX(props.match.params.id, res => {
            console.log("download complete");
        })

    }
    const next = () => {
        props.next();
    }
    const previous = () => {
        props.previous();
    }
    return (
        <div className='container-fluid '>

            <div className='row mt-3'>
                <div className='col-6' >
                    <h4>Summary</h4>
                </div>
                <div className='col-6' >
                    <button type="button" className="btn btn-sm btn-outline-secondary float-end" style={{ marginLeft: "15px" }} onClick={() => downloadPPTX(props.match.params.id)} ><i className="mdi mdi-download " aria-hidden="true" ></i> Download Slides</button>

                    <button type="button" className="btn btn-sm btn-outline-secondary float-end" style={{ marginLeft: "15px" }} onClick={() => setSharePop(true)} ><i className="mdi mdi-share-variant " aria-hidden="true" ></i> Share Model</button>
                </div>

            </div>
            <div className='row  gx-0 '>

                <div className='col-12' >

                    <div className="container-fluid" >
                        <div className="row filter-bar gx-3" style={{ color: "#ffff", padding: "0px 0px 10px 0px", margin: "10px 0px", borderRadius: "5px" }}>


                            <div className="col-3 p-4">
                                <div className="summary_label" >Model Name</div>
                                <div className="summary_data"  >{data.step1__meta_model_name}</div>
                            </div>
                            <div className="col-3 p-4">
                                <div className="summary_label" >Customer Name</div>
                                <div className="summary_data"  >{data.step1__meta_customer_name}</div>
                            </div>
                            <div className="col-3 p-4">
                                <div className="summary_label" >Country</div>
                                <div className="summary_data"   >{data.step1__meta_country}</div>
                            </div>

                            <div className="col-3 p-4">
                                <div className="summary_label" >Owner</div>
                                <div className="summary_data"  >{data.step1__meta_owner}</div>
                            </div>
                            {/* </div>
                            </div>
                            <div className="container-fluid mt-0 " >
                            <div className="row gx-0"> */}
                            <div className="col-2">
                                <div className='row ' style={{ textAlign: "center", background: "rgba(0,0, 0, 0.2)", color: "#ffff", padding: "5px", margin: "3px", borderRadius: "5px" }}>
                                    <div style={{ textTransform: "uppercase", fontSize: "0.65em", height: "35px" }}>AVERAGE PRODUCTIVITY</div>
                                    <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>{formatCurrency(data.step7__average_productivity_increase, 2)}%</div>
                                    <div style={{ textTransform: "uppercase", fontSize: "0.45em", height: "25px" }}>INCREASE</div>
                                </div>
                            </div>

                            <div className="col-2">
                                <div className='row ' style={{ textAlign: "center", background: "rgba(0,0, 0, 0.2)", color: "#ffff", padding: "5px", margin: "3px", borderRadius: "5px" }}>
                                    <div style={{ textTransform: "uppercase", fontSize: "0.65em", height: "35px" }}>EFFICIENCY</div>
                                    <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>{formatCurrency(data.step7__increased_procedures_per_day, 0)}</div>
                                    <div style={{ textTransform: "uppercase", fontSize: "0.45em", height: "25px" }}>increased procedures per day</div>
                                </div>
                            </div>
                            <div className="col-2 ">
                                <div className='row  filter-bar' style={{ textAlign: "center", background: "rgba(0,0, 0, 0.2)", color: "#ffff", padding: "5px", margin: "3px", borderRadius: "5px" }}>
                                    <div style={{ textTransform: "uppercase", fontSize: "0.65em", height: "35px" }}>EFFICIENCY</div>
                                    <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>{formatCurrency(data.step8_additional_operating_days, 0)}</div>
                                    <div style={{ textTransform: "uppercase", fontSize: "0.45em", height: "25px" }}>Additional operating days</div>
                                </div>
                            </div>

                            <div className="col-2 ">
                                <div className='row  filter-bar' style={{ textAlign: "center", background: "rgba(0,0, 0, 0.2)", color: "#ffff", padding: "5px", margin: "3px", borderRadius: "5px" }}>
                                    <div style={{ textTransform: "uppercase", fontSize: "0.65em", height: "35px" }}>UPTIME</div>
                                    <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>{formatCurrency(data.step8_additional_procedures, 0)}</div>
                                    <div style={{ textTransform: "uppercase", fontSize: "0.45em", height: "25px" }}>Additional procedures per day</div>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className='row  filter-bar' style={{ textAlign: "center", background: "rgba(0,0, 0, 0.2)", color: "#ffff", padding: "5px", margin: "3px", borderRadius: "5px" }}>
                                    <div style={{ textTransform: "uppercase", fontSize: "0.65em", height: "35px" }}>BREAK-EVEN<br /><span style={{ fontSize: "0.65em" }}>with Cybersecurity</span></div>
                                    <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>{breakEven && breakEven.break_even ? breakEven.break_even : 'NET LOSS'}</div>
                                    <div style={{ textTransform: "uppercase", fontSize: "0.45em", height: "25px" }}>Months in Future</div>
                                </div>
                            </div>

                            <div className="col-2">
                                <div className='row  filter-bar' style={{ textAlign: "center", background: "rgba(0,0, 0, 0.2)", color: "#ffff", padding: "5px", margin: "3px", borderRadius: "5px" }}>
                                    <div style={{ textTransform: "uppercase", fontSize: "0.65em", height: "35px" }}>BREAK-EVEN<br /><span style={{ fontSize: "0.65em" }}>without Cybersecurity</span></div>
                                    <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>{breakEven && breakEven.break_even_ex ? breakEven.break_even_ex : 'NET LOSS'}</div>
                                    <div style={{ textTransform: "uppercase", fontSize: "0.45em", height: "25px" }}>Months in Future</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pb-4">
                        <div className='col-2'>
                            <SelectField
                                fieldColor="orange"
                                fieldName="adjustment"
                                value={(data && data["adjustment"] && accuracies.find(item => item.value === data["adjustment"]))}
                                options={accuracies}
                                label="Adjustment"
                                required={true}
                                handleChange={handleChange}
                                error={errors["adjustment"]}
                            />
                        </div>

                        <div className='col-2'>
                            <SelectField
                                fieldColor="orange"
                                fieldName="accuracy_of_estimate"
                                value={(data && data["accuracy_of_estimate"] && accuracies.find(item => item.value === data["accuracy_of_estimate"]))}
                                options={accuracies}
                                label="Accuracy of Estimates (Break-Even)"
                                required={true}
                                handleChange={handleChange}
                                error={errors["accuracy_of_estimate"]}
                            />
                        </div>
                    </div>
                    <Tabs style={{ width: "100%" }} variant="fullWidth">
                        <TabList>
                            <Tab >Summary Of Costs and Revenues</Tab >
                            <Tab >Break-Even</Tab >
                        </TabList>
                        <TabPanel>
                            <div className="container-fluid mt-1" >

                                <div className="row ">
                                    <div className="col-8 pt-4">
                                        <h4>Source of values</h4>
                                    </div>
                                    <div className="col-4 pt-4">
                                        <h4>Medigate Platform Potential ROI</h4>
                                    </div>
                                    <div className="col-8 pt-4" style={{ height: "600px" }}>
                                        <ROIPie data={outer} />
                                    </div>
                                    <div className="col-4 pt-4" style={{ height: "580px" }}>
                                        <ROILine data={roi} />
                                    </div>
                                </div>
                            </div>

                            {summary && summary.length > 0 && <>

                                <table className='table'>
                                    <colgroup>
                                        <col span="1" style={{ width: "40%" }} />
                                        <col span="1" style={{ width: "10%" }} />
                                        <col span="1" style={{ width: "10%" }} />
                                        <col span="1" style={{ width: "10%" }} />
                                        <col span="1" style={{ width: "10%" }} />
                                        <col span="1" style={{ width: "10%" }} />
                                        <col span="1" style={{ width: "10%" }} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col">System Costs</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Year 1</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Year 2</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Year 3</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Year 4</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Year 5</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {summary
                                            .filter(item => item.item_type === "System Costs")
                                            .map(item => {
                                                return <tr key={item.item_label} style={{ background: item.item_label.includes("Total") ? "#e5e5e5" : "#ffffff" }}>
                                                    <td >{item.item_label}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr1, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr2, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr3, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr4, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr5, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.total, 0)}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>

                                <table className='table'>
                                    <colgroup>
                                        <col span="1" style={{ width: "30%" }} />
                                        <col span="1" style={{ width: "10%" }} />
                                        <col span="1" style={{ width: "10%" }} />
                                        <col span="1" style={{ width: "10%" }} />
                                        <col span="1" style={{ width: "10%" }} />
                                        <col span="1" style={{ width: "10%" }} />
                                        <col span="1" style={{ width: "10%" }} />
                                        <col span="1" style={{ width: "10%" }} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col">&nbsp;</th>
                                            <th scope="col">Months to Value Realization</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Year 1</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Year 2</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Year 3</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Year 4</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Year 5</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={8}><h4>Recovered Revenues</h4></td>
                                        </tr>
                                        {summary
                                            .filter(item => item.item_type === "Recovered Revenues")
                                            .map(item => {
                                                return <tr key={item.item_label} style={{ background: item.item_label.includes("Total") ? "#e5e5e5" : "#ffffff" }}>
                                                    <td >{item.item_label}</td>
                                                    <td >{item.mvr ? item.mvr : ""}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr1, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr2, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr3, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr4, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr5, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.total, 0)}</td>
                                                </tr>
                                            })
                                        }
                                        <tr>
                                            <td colSpan={8}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={8}><h4>Streamlined Maintenance</h4></td>
                                        </tr>
                                        {summary
                                            .filter(item => item.item_type === "Streamlined Maintenance")
                                            .map(item => {
                                                return <tr key={item.item_label} style={{ background: item.item_label.includes("Total") ? "#e5e5e5" : "#ffffff" }}>
                                                    <td >{item.item_label}</td>
                                                    <td >{item.mvr ? item.mvr : ""}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr1, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr2, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr3, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr4, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr5, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.total, 0)}</td>
                                                </tr>
                                            })
                                        }
                                        <tr>
                                            <td colSpan={8}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={8}><h4>Cybersecurity Cost Avoidance</h4></td>
                                        </tr>
                                        {summary
                                            .filter(item => item.item_type === "Cybersecurity Cost Avoidance")
                                            .map(item => {
                                                return <tr key={item.item_label} style={{ background: item.item_label.includes("Total") ? "#e5e5e5" : "#ffffff" }}>
                                                    <td >{item.item_label}</td>
                                                    <td >{item.mvr ? item.mvr : ""}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr1, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr2, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr3, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr4, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr5, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.total, 0)}</td>
                                                </tr>
                                            })
                                        }
                                        <tr>
                                            <td colSpan={8}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={8}><h4>Device Lifecycle Savings</h4></td>
                                        </tr>

                                        {summary
                                            .filter(item => item.item_type === "Device Lifecycle Savings")
                                            .map(item => {
                                                return <tr key={item.item_label} style={{ background: item.item_label.includes("Total") ? "#e5e5e5" : "#ffffff" }}>
                                                    <td >{item.item_label}</td>
                                                    <td >{item.mvr ? item.mvr : ""}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr1, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr2, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr3, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr4, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr5, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.total, 0)}</td>
                                                </tr>
                                            })
                                        }
                                        <tr>
                                            <td colSpan={8}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={8}><h4>ROI</h4></td>
                                        </tr>
                                        {totals
                                            //.filter(item => item.line_type === "Total ROI")
                                            .map(item => {
                                                return <tr key={item.line_type} style={{ background: "#ffffff", fontWeight: "600" }}>
                                                    <td >{item.line_type}</td>
                                                    <td >&nbsp;</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr1, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr2, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr3, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr4, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>${formatCurrency(item.yr5, 0)}</td>
                                                    <td style={{ textAlign: "right" }}>{item.line_type === "Total ROI" ? ("$" + formatCurrency(item.total, 0)) : ""}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </>}
                        </TabPanel>
                        <TabPanel>
                            <div className="container-fluid mt-1" >

                                <div className="row ">

                                    <div className="col-12 pt-4">
                                        <h4>Cumulative Additional Revenue</h4>
                                    </div>
                                    <div className="col-12 pt-4" style={{ height: "600px" }}>
                                        <AccuracyLine data={revChart} />
                                    </div>

                                </div>
                            </div>
                            <div className="container-fluid" >

                                <div className="row ">
                                    <div className='col-12'>
                                        <table className='table'>
                                            <colgroup>
                                                <col span="1" style={{ width: "40%" }} />
                                                <col span="1" style={{ width: "10%" }} />
                                                <col span="1" style={{ width: "10%" }} />
                                                <col span="1" style={{ width: "10%" }} />
                                                <col span="1" style={{ width: "10%" }} />
                                                <col span="1" style={{ width: "10%" }} />
                                                <col span="1" style={{ width: "10%" }} />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th scope="col">&nbsp;</th>
                                                    <th scope="col" style={{ textAlign: "right" }}>Year 1</th>
                                                    <th scope="col" style={{ textAlign: "right" }}>Year 2</th>
                                                    <th scope="col" style={{ textAlign: "right" }}>Year 3</th>
                                                    <th scope="col" style={{ textAlign: "right" }}>Year 4</th>
                                                    <th scope="col" style={{ textAlign: "right" }}>Year 5</th>
                                                    <th scope="col" style={{ textAlign: "right" }}>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={7}><h4>100% of Estimates Achieved</h4></td>
                                                </tr>
                                                {estimates && estimates.annual_ord_value_full &&
                                                    <tr>
                                                        <td >Annual Medigate Value (100%)</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_full.yr1, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_full.yr2, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_full.yr3, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_full.yr4, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_full.yr5, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_full.total, 0)}</td>
                                                    </tr>
                                                }

                                                {estimates && estimates.annual_ord_cost &&
                                                    <tr>
                                                        <td >Annual Medigate Cost</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr1, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr2, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr3, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr4, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr5, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.total, 0)}</td>
                                                    </tr>
                                                }
                                                {estimates && estimates.additional_revenue_full &&
                                                    <tr style={{ background: "#e5e5e5" }}>
                                                        <td >Additional Revenue (100%)</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_full.yr1, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_full.yr2, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_full.yr3, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_full.yr4, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_full.yr5, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_full.total, 0)}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <td colSpan={7}>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={7}><h4>Adjusted {data.accuracy_of_estimate} of Estimates Achieved</h4></td>
                                                </tr>
                                                {estimates && estimates.annual_ord_value_adjusted &&
                                                    <tr>
                                                        <td >Annual Medigate Value (Adjusted {data.accuracy_of_estimate})</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_adjusted.yr1, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_adjusted.yr2, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_adjusted.yr3, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_adjusted.yr4, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_adjusted.yr5, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_adjusted.total, 0)}</td>
                                                    </tr>
                                                }
                                                {estimates && estimates.annual_ord_cost &&
                                                    <tr>
                                                        <td >Annual Medigate Cost</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr1, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr2, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr3, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr4, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr5, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.total, 0)}</td>
                                                    </tr>
                                                }
                                                {estimates && estimates.additional_revenue_adjusted &&
                                                    <tr style={{ background: "#e5e5e5" }}>
                                                        <td >Additional Revenue (100%)</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_adjusted.yr1, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_adjusted.yr2, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_adjusted.yr3, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_adjusted.yr4, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_adjusted.yr5, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_adjusted.total, 0)}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <td colSpan={7}>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={7}><h4>100% of Estimates Achieved (Excluding Cybersecurity Cost Avoidance)</h4></td>
                                                </tr>
                                                {estimates && estimates.annual_ord_value_full_ex &&
                                                    <tr>
                                                        <td >Annual Medigate Value (100%)</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_full_ex.yr1, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_full_ex.yr2, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_full_ex.yr3, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_full_ex.yr4, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_full_ex.yr5, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_full_ex.total, 0)}</td>
                                                    </tr>
                                                }

                                                {estimates && estimates.annual_ord_cost &&
                                                    <tr>
                                                        <td >Annual Medigate Cost</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr1, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr2, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr3, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr4, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr5, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.total, 0)}</td>
                                                    </tr>
                                                }
                                                {estimates && estimates.additional_revenue_full_ex &&
                                                    <tr style={{ background: "#e5e5e5" }}>
                                                        <td >Additional Revenue (100%)</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_full_ex.yr1, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_full_ex.yr2, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_full_ex.yr3, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_full_ex.yr4, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_full_ex.yr5, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_full_ex.total, 0)}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <td colSpan={7}>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={7}><h4>Adjusted {data.accuracy_of_estimate} of Estimates Achieved (Excluding Cybersecurity Cost Avoidance)</h4></td>
                                                </tr>
                                                {estimates && estimates.annual_ord_value_adjusted_ex &&
                                                    <tr>
                                                        <td >Annual Medigate Value (Adjusted {data.accuracy_of_estimate})</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_adjusted_ex.yr1, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_adjusted_ex.yr2, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_adjusted_ex.yr3, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_adjusted_ex.yr4, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_adjusted_ex.yr5, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_value_adjusted_ex.total, 0)}</td>
                                                    </tr>
                                                }
                                                {estimates && estimates.annual_ord_cost &&
                                                    <tr>
                                                        <td >Annual Medigate Cost</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr1, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr2, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr3, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr4, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.yr5, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.annual_ord_cost.total, 0)}</td>
                                                    </tr>
                                                }
                                                {estimates && estimates.additional_revenue_adjusted_ex &&
                                                    <tr style={{ background: "#e5e5e5" }}>
                                                        <td >Additional Revenue (100%)</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_adjusted_ex.yr1, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_adjusted_ex.yr2, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_adjusted_ex.yr3, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_adjusted_ex.yr4, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_adjusted_ex.yr5, 0)}</td>
                                                        <td style={{ textAlign: "right" }}>${formatCurrency(estimates.additional_revenue_adjusted_ex.total, 0)}</td>
                                                    </tr>
                                                }

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>

                        </TabPanel>
                    </Tabs>
                </div>

            </div>

            <div className='mt-3'>
                <div className="clearfix">
                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => previous()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                </div>
            </div>
            {
                shareOptions && shareOptions.length > 0 && sharePop &&
                <div className='popup'>
                    <div className='popup_content'>
                        <div className="popup-title">
                            Share Model
                        </div>
                        <div style={{ maxHeight: "200px", fontSize: "13px" }}>
                            <label style={{ fontSize: "13px" }}>USERS</label>
                            <MultySelectDropdown
                                placeholder="Select"
                                closeMenuOnSelect={false}
                                value={sharedModelUsers}
                                options={shareOptions}
                                onChange={(_selectedValues) => {
                                    setSharedModelUsers(_selectedValues)
                                }}
                            />
                            {sharedModelUsersMsg && <div style={{ wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: sharedModelUsersMsg }} />}
                        </div>
                        <div className="popup-btn-panel">
                            <button type="button" className="btn btn-g btn-primary float-end" style={{ marginLeft: "5px" }} onClick={() => saveSharedUsers()} >Save</button>
                            <button type="button" className="btn btn-g btn-secondary float-end" onClick={() => setSharePop(false)} >Cancel</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Summary;