import React, { useEffect, useState } from 'react';
import Header from '../../Components/layout/Header';
import BreadCrumb from './BreadCrumb';
import ModelDetail from './wizard/Step1ModelDetail';
import SystemCost from './wizard/Step2SystemCost';
import FleetManagement from './wizard/Step3FleetManagement';
import OutdatedOS from './wizard/Step4OutdatedOS';
import OverUtilization from './wizard/Step5OverUtilization';
import UnderUtilization from './wizard/Step6UnderUtilization';
import IncreasedThroughput from './wizard/Step7IncreasedThroughput';
import Uptime from './wizard/Step8Uptime';
import SupportLabour from './wizard/Step9SupportLabour';
import BreachFines from './wizard/Step11BreachFines';
import DeviceLifecycle from './wizard/Step12DeviceLifecycle';
import ClinicalEngineering from './wizard/Step13ClinicalEngineering';
import CyberHygiene from './wizard/Step14CyberHygiene';
import NetworkManagement from './wizard/Step15NetworkManagement';
import NetworkCreation from './wizard/Step16NetworkCreation';
import Summary from './wizard/Step17Summary';
import PartsManagement from './wizard/Step10PartsManagement';
import { useAuthState } from "../../Context";
import * as service from "../../services";
import {useStateWithCallback} from '../../Components/useStateWithCallback'

function BvaHcCreateModel(props) {
    const [step, setStep] = useState(parseInt(props.match.params.ref));
    const [steps, setSteps] = useState(17);
    const userDetails = useAuthState();
    const { user } = userDetails.user;
    const mvrs = [
        { value: 1, label: '1' }, { value: 2, label: '2' }, { value: 3, label: '3' }, { value: 4, label: '4' },
        { value: 5, label: '5' }, { value: 6, label: '6' }, { value: 7, label: '7' }, { value: 8, label: '8' },
        { value: 9, label: '9' }, { value: 10, label: '10' }, { value: 11, label: '11' }, { value: 12, label: '12' },
        { value: 13, label: '13' }, { value: 14, label: '14' }, { value: 15, label: '15' }, { value: 16, label: '16' },
        { value: 17, label: '17' }, { value: 18, label: '18' }, { value: 19, label: '19' }, { value: 20, label: '20' },
        { value: 21, label: '21' }, { value: 22, label: '22' }, { value: 23, label: '23' }, { value: 24, label: '24' }
    ];
    const [loading, setLoading] = useState(true);
    const [lookup, setLookup] = useState([]);
    const [modelId, setModelId] = useState(parseInt(props.match.params.id));
    const [data, setData] = useStateWithCallback({});
    const [templates, setTemplates] = useStateWithCallback({});

    const [errors, setErrors] = useState({});
    useEffect(() => {
        loadModel( modelId, ()=>{
            loadTemplates( () => {

            });
        });
    }, []);


    const handleChange = (prop, value) => {
        
        if(prop==="step1__meta_template" && modelId===0){
            service.getBvaHcData("get-std-data",{step1__meta_template: value}, (res) =>{
                if(res && res.length===1){
                    setData({ ...data, [prop]: value, ...res[0] });
                } else{
                    setData({ ...data, [prop]: value });
                }
            });
        } else{
            setData({ ...data, [prop]: value });
        }
    };

    const validate = () => {
        setErrors({});
        let isValid = true;
        let validation = [];
        if (step === 1) {
            validation.push({ field: 'step1__meta_model_name', msg: 'Please enter Model Name.' });
            validation.push({ field: 'step1__meta_customer_name', msg: 'Please enter Customer Name.' });
            validation.push({ field: 'step1__meta_owner', msg: 'Please enter Owner Name.' });
            validation.push({ field: 'step1__meta_country', msg: 'Please select Country.' });
        } else if (step === 2) {
            validation.push({ field: 'step2__software_fee_annual', msg: 'Please enter Order Software Fee - Annual.' });
            validation.push({ field: 'step2__sensors_one_time_fee', msg: 'Please enter Order Sensors - One Time Fee.' });
            validation.push({ field: 'step2__sensors_maintenance_fee_annual', msg: 'Please enter Sensors Maintenance - Anuual.' });
            validation.push({ field: 'step2__onsite_training_one_time_fee', msg: 'Please enter Two Day Onsite Training - One Time Fee.' });
            validation.push({ field: 'step2__deployment_one_time_fee', msg: 'Please enter Deployment of Sensors - One Time Fee.' });
        } else if (step === 3) {
            validation.push({ field: 'step3__usage_hours_ip', msg: 'Please enter Average Hours of  Usage (Weekly) .' });
            validation.push({ field: 'step3__optimal_utilization_ip', msg: 'Please enter Optimal  Utilization.' });
            validation.push({ field: 'step3__avg_device_cost_ip', msg: 'Please enter Average Annual  Lease/Purchase Cost Per Device.' });
            validation.push({ field: 'step3__avg_device_maintenance_ip', msg: 'Please enter Annual Maintenance Cost as a Percentage of Lease/Purchase Cost.' });
            validation.push({ field: 'step3__usage_hours_pm', msg: 'Please enter Average Hours of  Usage (Weekly) .' });
            validation.push({ field: 'step3__optimal_utilization_pm', msg: 'Please enter Optimal  Utilization.' });
            validation.push({ field: 'step3__avg_device_cost_pm', msg: 'Please enter Average Annual  Lease/Purchase Cost Per Device.' });
            validation.push({ field: 'step3__avg_device_maintenance_pm', msg: 'Please enter Annual Maintenance Cost as a Percentage of Lease/Purchase Cost.' });
            validation.push({ field: 'step3__usage_hours_am', msg: 'Please enter Average Hours of  Usage (Weekly) .' });
            validation.push({ field: 'step3__optimal_utilization_am', msg: 'Please enter Optimal  Utilization.' });
            validation.push({ field: 'step3__avg_device_cost_am', msg: 'Please enter Average Annual  Lease/Purchase Cost Per Device.' });
            validation.push({ field: 'step3__avg_device_maintenance_am', msg: 'Please enter Annual Maintenance Cost as a Percentage of Lease/Purchase Cost.' });
        } else if (step ===4 ){
            validation.push({ field: 'step4__number_of_medical_devices', msg: 'Please enter Number of Medical Devices .' });
            validation.push({ field: 'step4__number_of_medical_workstations', msg: 'Please enter Number of Medical Workstations .' });
            validation.push({ field: 'step4__number_of_facilities_devices', msg: 'Please enter Number of Facilities Devices .' });
            validation.push({ field: 'step4__number_of_media', msg: 'Please enter Number of Media .' });
            validation.push({ field: 'step4__number_of_workstations', msg: 'Please enter Number of Workstations .' });
            validation.push({ field: 'step4__average_device_cost', msg: 'Please enter Average cost of device .' });
        }
        validation.map(item => {
            if (!data[item.field] || data[item.field].length <= 0) {
                if(data[item.field]+""==="0"){

                } else{
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                
                isValid = false;
                }
            }
            return item;
        });
        return isValid;
    }

    useEffect(() => {
        props.history.push('/create-hc-model/'+modelId+'/'+step);
    }, [modelId, step]);

    const navigate =(s) =>{
        setStep(s);
    }

    const previous = () => {
        
        loadModel(modelId, ()=>{
            if (step > 0) {
                setStep(step - 1);
            }
        });
    }
    const loadModel=(model_id, fn)=>{
        service.getBvaHcData("get_model_input",{model_id:model_id}, (res) =>{
            if(res && res.length===1){
                setData(res[0]);
                fn();
            }
        });
    }
    const loadTemplates=( fn)=>{
        service.getBvaHcData("get_templates",{model_id:modelId}, (res) =>{
            if(res && res.length>0){
                setTemplates(res);
                fn();
            }
        });
    }
    const next = (params) => {
        if (validate()) {
            service.getBvaHcData("submit_step"+step,{...data, model_id:modelId, ...params}, (res) =>{
                setModelId(res.model_id);
                loadModel(res.model_id, ()=>{
                    if(step<steps){
                        setStep(step+1);
                    }
                });
            });
        } 
    }
    return (
        <>
            <Header {...props} />
            <main className="auto-height">
                <div className="container-fluid auto-height">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 shadow-dreamy-small" style={{ background: "#fafafa"}}>
                            {data && data.max_step_reached && <BreadCrumb {...props} step={step} data={data} navigate={navigate}/> }

                        </div>
                        <div className="col-lg-9  col-md-8" >
                            {step === 1 && <ModelDetail {...props} mvrs={mvrs} data={data}  errors={errors} templates={templates}  handleChange={handleChange} previous={previous} next={next} setData={setData} />}
                            {step === 2 && <SystemCost {...props} mvrs={mvrs} data={data} errors={errors}   handleChange={handleChange} previous={previous} next={next} setData={setData} />}
                            {step === 3 && <FleetManagement {...props} mvrs={mvrs} data={data} errors={errors}   handleChange={handleChange} previous={previous} next={next} setData={setData}  />}
                            {step === 4 && <OutdatedOS {...props} mvrs={mvrs} data={data} errors={errors}   handleChange={handleChange} previous={previous} next={next} setData={setData}  />}
                            {step === 5 && <OverUtilization {...props} mvrs={mvrs} data={data} errors={errors}   handleChange={handleChange} previous={previous} next={next} setData={setData}  />}
                            {step === 6 && <UnderUtilization {...props} mvrs={mvrs} data={data} errors={errors}   handleChange={handleChange} previous={previous} next={next} setData={setData}  />}
                            {step === 7 && <IncreasedThroughput {...props} mvrs={mvrs} data={data} errors={errors}   handleChange={handleChange} previous={previous} next={next} setData={setData}  />}
                            {step === 8 && <Uptime {...props} mvrs={mvrs} data={data} errors={errors}   handleChange={handleChange} previous={previous} next={next} setData={setData}  />}
                            {step === 9 && <SupportLabour {...props} mvrs={mvrs} data={data} errors={errors}   handleChange={handleChange} previous={previous} next={next} setData={setData}  />}
                            {step === 10 && <PartsManagement {...props} mvrs={mvrs} data={data} errors={errors}   handleChange={handleChange} previous={previous} next={next} setData={setData}  />}
                            {step === 11 && <BreachFines {...props} mvrs={mvrs} data={data} errors={errors}   handleChange={handleChange} previous={previous} next={next} setData={setData}  />}
                            {step === 12 && <DeviceLifecycle {...props} mvrs={mvrs} data={data} errors={errors}   handleChange={handleChange} previous={previous} next={next} setData={setData}  />}
                            {step === 13 && <ClinicalEngineering {...props} mvrs={mvrs} data={data} errors={errors}   handleChange={handleChange} previous={previous} next={next} setData={setData}  />}
                            {step === 14 && <CyberHygiene {...props} mvrs={mvrs} data={data} errors={errors}   handleChange={handleChange} previous={previous} next={next} setData={setData}  />}
                            {step === 15 && <NetworkManagement {...props} mvrs={mvrs} data={data} errors={errors}   handleChange={handleChange} previous={previous} next={next} setData={setData}  />}    
                            {step === 16 && <NetworkCreation {...props} mvrs={mvrs} data={data} errors={errors}   handleChange={handleChange} previous={previous} next={next} setData={setData}  />}    
                            {step === 17 && <Summary {...props} mvrs={mvrs} data={data} errors={errors}   handleChange={handleChange} previous={previous} next={next} setData={setData}  />}
                            

                            
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default BvaHcCreateModel;


