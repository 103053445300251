import React from 'react';
import Text from './fields/Text';
import { formatCurrency } from '../../../Components/Util';
import SelectField from './fields/Select';
function OutdatedOS(props) {
    const data = props.data;
    const errors = props.errors;
    const handleChange = props.handleChange;
    
    const total_cost_to_replace = (parseFloat(data.step4__number_of_medical_devices) +
        parseFloat(data.step4__number_of_medical_workstations) +
        parseFloat(data.step4__number_of_facilities_devices) +
        parseFloat(data.step4__number_of_media) +
        parseFloat(data.step4__number_of_workstations)) *
        parseFloat(data.step4__average_device_cost);
    const estimated_depriciation = 0.2 * total_cost_to_replace;
    const estimated_monthly_saving = estimated_depriciation / 12;
    const next=() =>{
        props.next({step4__total: estimated_depriciation});
    }
    const previous=() =>{
        props.previous();
    }
    return (
        <div className='container'>
            
            <div className='row mt-3'>
                <div className='col-10' >
                    <h4>Delayed Device Replacement</h4>
                </div>
                <div className='col-2' >
                <SelectField
                    fieldColor="orange"
                    fieldName="step4__mvr"
                    value={(data && data["step4__mvr"] && props.mvrs.find(item => item.value === data["step4__mvr"])) || null}
                    options={props.mvrs}
                    label="Months to Value Realization"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step4__mvr"]}
                />
                </div>
            </div>
            <div className='row mt-3 gx-5 '>
                <div className='col-4' style={{ borderRight: "1px solid #e5e5e5" }}>
                    <Text
                        fieldColor="blue"
                        fieldName="step4__number_of_medical_devices"
                        value={data["step4__number_of_medical_devices"] + ""}
                        label="Number of Medical Devices"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step4__number_of_medical_devices"]}
                        pattern="[0-9]*"
                    />
                    <Text
                        fieldColor="blue"
                        fieldName="step4__number_of_medical_workstations"
                        value={data["step4__number_of_medical_workstations"] + ""}
                        label="Number of Medical Workstations"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step4__number_of_medical_workstations"]}
                        pattern="[0-9]*"
                    />
                    <Text
                        fieldColor="blue"
                        fieldName="step4__number_of_facilities_devices"
                        value={data["step4__number_of_facilities_devices"] + ""}
                        label="Number of Facilities Devices"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step4__number_of_facilities_devices"]}
                        pattern="[0-9]*"
                    />
                    <Text
                        fieldColor="blue"
                        fieldName="step4__number_of_media"
                        value={data["step4__number_of_media"] + ""}
                        label="Number of Media"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step4__number_of_media"]}
                        pattern="[0-9]*"
                    />
                    <Text
                        fieldColor="blue"
                        fieldName="step4__number_of_workstations"
                        value={data["step4__number_of_workstations"] + ""}
                        label="Number of Workstations"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step4__number_of_workstations"]}
                        pattern="[0-9]*"
                    />
                    <Text
                        fieldColor="blue"
                        fieldName="step4__average_device_cost"
                        value={data["step4__average_device_cost"] + ""}
                        label="Average cost of device "
                        required={true}
                        handleChange={handleChange}
                        error={errors["step4__average_device_cost"]}
                        leftMarker="$"
                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                    />

                </div>
                <div className='col-8' >
                    <div className='row mt-3' style={{ textAlign: "center", background: estimated_depriciation>0?"#4D9262":"#cc0000", color: "#ffff", padding: "10px", borderRadius: "5px" }}>
                        <div style={{ textTransform: "uppercase", fontSize: "0.65em" }}>Delayed Device Replacement <br /> Revenue Recovery</div>
                        <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>
                            ${formatCurrency(estimated_depriciation, 0)}
                        </div>
                    </div>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>Total Cost to Replace Devices with Outdated OS </td>
                                <td style={{ textAlign: "right" }}>${formatCurrency(total_cost_to_replace, 2)}</td>
                            </tr>
                            <tr>
                                <td>Estimated Annual Depreciation (over 5 years) </td>
                                <td style={{ textAlign: "right" }}>${formatCurrency(estimated_depriciation, 2)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='mt-3'>
                <div className="clearfix">
                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => previous()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                    <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => next()} ><i className="mdi mdi-skip-next " aria-hidden="true" ></i> Next</button>
                </div>
            </div>
        </div>
    )
}

export default OutdatedOS;