import React from 'react';
import Text from './fields/Text';
import { formatCurrency, round } from '../../../Components/Util';
import SelectField from './fields/Select';

function ClinicalEngineering(props) {
    const data = props.data;
    const errors = props.errors;
    const handleChange = props.handleChange;

    const job_classes = [
        { value: 'Clinical Staff', label: 'Clinical Staff' },
        { value: 'Clerical Staff', label: 'Clerical Staff' },
        { value: 'Network Engineer', label: 'Network Engineer' },
        { value: 'InfoSec Engineer', label: 'InfoSec Engineer' },
        { value: 'Biomedical Engineer', label: 'Biomedical Engineer' }
    ];
    const ramp ={
        yr1: 0.25,
        yr2: 0.85,
        yr3: 1,
        yr4: 1,
        yr5: 1
    }

    const get_salary = (jc) =>{
        let val =0;
        if (jc==='Clinical Staff') {
            val= parseFloat(data.step12__salary_clinical_staff)/50/40;
        } else if(jc==='Clerical Staff'){
            val=  parseFloat(data.step12__salary_clerical_staff)/50/40;
        } else if(jc==='Network Engineer'){
            val=  parseFloat(data.step12__salary_network_engineer)/50/40;
        } else if(jc==='InfoSec Engineer'){
            val=  parseFloat(data.step12__salary_infosec_engineer)/50/40;
        } else if(jc==='Biomedical Engineer'){
            val=  parseFloat(data.step12__salary_biomedical_engineer)/50/40;
        }
        console.log(jc, val);
        return val;
    }

    const growth_devices_yr1 = parseFloat(data.step12__number_of_devices_across_facilities) * (1 + parseFloat(data.step12__cagr) / 100);
    //const new_devices_yr1 = growth_devices_yr1 - parseFloat(data.step12__number_of_devices_across_facilities);
    
    const growth_owned_devices_yr1 = (growth_devices_yr1  - Math.round(growth_devices_yr1*parseFloat(data.step12__per_rental_devices) / 100));
    const growth_mobile_devices_yr1 = (growth_owned_devices_yr1 * (1 - parseFloat(data.step12__per_stationary_devices) / 100));
    const new_device_types_devices_yr1 = Math.ceil((parseFloat(data.step12__number_of_installed) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr1);
    const updated_devices_yr1 = Math.ceil((parseFloat(data.step12__number_of_updated) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_mobile_devices_yr1);
    const decomissioned_devices_yr1 = Math.ceil((parseFloat(data.step12__number_of_decommissioned) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr1);
    const reassigned_devices_yr1 = Math.ceil((parseFloat(data.step12__number_of_reassigned) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr1);
    const find_devices_yr1=parseFloat(data.step12__number_of_beds)*52;
    const pm_tickets_devices_yr1 = Math.ceil((parseFloat(data.step12__number_of_tickets) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr1);
    const recalled_devices_yr1 = Math.ceil((parseFloat(data.step12__number_of_recalled) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr1);
    

    const add_device_effort_current_yr1 = (round(parseFloat(data.step13__add_device_effort) * new_device_types_devices_yr1 / 60, 1));
    const update_device_effort_current_yr1 = (round(parseFloat(data.step13__update_device_effort) * updated_devices_yr1 / 60, 1)) ;
    const delete_device_effort_current_yr1 = (round(parseFloat(data.step13__delete_device_effort) * decomissioned_devices_yr1 / 60, 1));
    const reassigned_device_effort_current_yr1 = (round(parseFloat(data.step13__reassigned_device_effort) * reassigned_devices_yr1 / 60, 1));
    const find_device_effort_current_yr1 = (round(parseFloat(data.step13__find_device_effort) * find_devices_yr1 / 60, 1));
    const tickets_device_effort_current_yr1 = (round(parseFloat(data.step13__ticket_device_effort) * pm_tickets_devices_yr1 / 60, 1));
    const recalled_device_effort_current_yr1 = (round(parseFloat(data.step13__recalled_device_effort) * recalled_devices_yr1 / 60, 1));
    const reconcile_device_effort_current_yr1 = (round(parseFloat(data.step13__reconcile_device_effort) * parseFloat(data.step13__reconcile_device_volume_yr1) / 60, 1));

    const current_yr1 = Math.ceil(add_device_effort_current_yr1*get_salary(data.step13__add_device_job_class)
    + update_device_effort_current_yr1* get_salary(data.step13__update_device_job_class)
    + delete_device_effort_current_yr1*get_salary(data.step13__delete_device_job_class)
    + reassigned_device_effort_current_yr1*get_salary(data.step13__reassigned_device_job_class)
    + find_device_effort_current_yr1*get_salary(data.step13__find_device_job_class)
    + tickets_device_effort_current_yr1*get_salary(data.step13__ticket_device_job_class)
    + recalled_device_effort_current_yr1*get_salary(data.step13__recalled_device_job_class)
    + reconcile_device_effort_current_yr1*get_salary(data.step13__reconcile_device_job_class), 0);

    const add_device_effort_current_yr2 = round(add_device_effort_current_yr1 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const update_device_effort_current_yr2 = round(update_device_effort_current_yr1 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const delete_device_effort_current_yr2 = round(delete_device_effort_current_yr1 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const reassigned_device_effort_current_yr2 = round(reassigned_device_effort_current_yr1 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const find_device_effort_current_yr2 = round(find_device_effort_current_yr1 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const tickets_device_effort_current_yr2 = round(tickets_device_effort_current_yr1 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const recalled_device_effort_current_yr2 = round(recalled_device_effort_current_yr1 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const reconcile_device_effort_current_yr2 = round(reconcile_device_effort_current_yr1, 1);

    const current_yr2 = Math.ceil(add_device_effort_current_yr2*get_salary(data.step13__add_device_job_class)
    + update_device_effort_current_yr2* get_salary(data.step13__update_device_job_class)
    + delete_device_effort_current_yr2*get_salary(data.step13__delete_device_job_class)
    + reassigned_device_effort_current_yr2*get_salary(data.step13__reassigned_device_job_class)
    + find_device_effort_current_yr2*get_salary(data.step13__find_device_job_class)
    + tickets_device_effort_current_yr2*get_salary(data.step13__ticket_device_job_class)
    + recalled_device_effort_current_yr2*get_salary(data.step13__recalled_device_job_class)
    + reconcile_device_effort_current_yr2*get_salary(data.step13__reconcile_device_job_class), 0);

    const add_device_effort_current_yr3 = round(add_device_effort_current_yr2 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const update_device_effort_current_yr3 = round(update_device_effort_current_yr2 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const delete_device_effort_current_yr3 = round(delete_device_effort_current_yr2 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const reassigned_device_effort_current_yr3 = round(reassigned_device_effort_current_yr2 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const find_device_effort_current_yr3 = round(find_device_effort_current_yr2 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const tickets_device_effort_current_yr3 = round(tickets_device_effort_current_yr2 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const recalled_device_effort_current_yr3 = round(recalled_device_effort_current_yr2 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const reconcile_device_effort_current_yr3 = round(reconcile_device_effort_current_yr2, 1);

    const current_yr3 = Math.ceil(add_device_effort_current_yr3*get_salary(data.step13__add_device_job_class)
    + update_device_effort_current_yr3* get_salary(data.step13__update_device_job_class)
    + delete_device_effort_current_yr3*get_salary(data.step13__delete_device_job_class)
    + reassigned_device_effort_current_yr3*get_salary(data.step13__reassigned_device_job_class)
    + find_device_effort_current_yr3*get_salary(data.step13__find_device_job_class)
    + tickets_device_effort_current_yr3*get_salary(data.step13__ticket_device_job_class)
    + recalled_device_effort_current_yr3*get_salary(data.step13__recalled_device_job_class)
    + reconcile_device_effort_current_yr3*get_salary(data.step13__reconcile_device_job_class), 0);

    const add_device_effort_current_yr4 = round(add_device_effort_current_yr3 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const update_device_effort_current_yr4 = round(update_device_effort_current_yr3 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const delete_device_effort_current_yr4 = round(delete_device_effort_current_yr3 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const reassigned_device_effort_current_yr4 = round(reassigned_device_effort_current_yr3 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const find_device_effort_current_yr4 = round(find_device_effort_current_yr3 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const tickets_device_effort_current_yr4 = round(tickets_device_effort_current_yr3 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const recalled_device_effort_current_yr4 = round(recalled_device_effort_current_yr3 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const reconcile_device_effort_current_yr4 = round(reconcile_device_effort_current_yr3, 1);

    const current_yr4 = Math.ceil(add_device_effort_current_yr4*get_salary(data.step13__add_device_job_class)
    + update_device_effort_current_yr4* get_salary(data.step13__update_device_job_class)
    + delete_device_effort_current_yr4*get_salary(data.step13__delete_device_job_class)
    + reassigned_device_effort_current_yr4*get_salary(data.step13__reassigned_device_job_class)
    + find_device_effort_current_yr4*get_salary(data.step13__find_device_job_class)
    + tickets_device_effort_current_yr4*get_salary(data.step13__ticket_device_job_class)
    + recalled_device_effort_current_yr4*get_salary(data.step13__recalled_device_job_class)
    + reconcile_device_effort_current_yr4*get_salary(data.step13__reconcile_device_job_class), 0);

    const add_device_effort_current_yr5 = round(add_device_effort_current_yr4 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const update_device_effort_current_yr5 = round(update_device_effort_current_yr4 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const delete_device_effort_current_yr5 = round(delete_device_effort_current_yr4 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const reassigned_device_effort_current_yr5 = round(reassigned_device_effort_current_yr4 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const find_device_effort_current_yr5 = round(find_device_effort_current_yr4 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const tickets_device_effort_current_yr5 = round(tickets_device_effort_current_yr4 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const recalled_device_effort_current_yr5 = round(recalled_device_effort_current_yr4 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const reconcile_device_effort_current_yr5 = round(reconcile_device_effort_current_yr4, 1);

    const current_yr5 = Math.ceil(add_device_effort_current_yr5*get_salary(data.step13__add_device_job_class)
    + update_device_effort_current_yr5* get_salary(data.step13__update_device_job_class)
    + delete_device_effort_current_yr5*get_salary(data.step13__delete_device_job_class)
    + reassigned_device_effort_current_yr5*get_salary(data.step13__reassigned_device_job_class)
    + find_device_effort_current_yr5*get_salary(data.step13__find_device_job_class)
    + tickets_device_effort_current_yr5*get_salary(data.step13__ticket_device_job_class)
    + recalled_device_effort_current_yr5*get_salary(data.step13__recalled_device_job_class)
    + reconcile_device_effort_current_yr5*get_salary(data.step13__reconcile_device_job_class), 0);

    const add_device_effort_proposed_yr1= round(add_device_effort_current_yr1-(add_device_effort_current_yr1*parseFloat(data.step13__add_device_impact)/100*ramp.yr1),4);
    const update_device_effort_proposed_yr1= round(update_device_effort_current_yr1-(update_device_effort_current_yr1*parseFloat(data.step13__update_device_impact)/100*ramp.yr1),4);
    const delete_device_effort_proposed_yr1= round(delete_device_effort_current_yr1-(delete_device_effort_current_yr1*parseFloat(data.step13__delete_device_impact)/100*ramp.yr1),4);
    const reassigned_device_effort_proposed_yr1= round(reassigned_device_effort_current_yr1-(reassigned_device_effort_current_yr1*parseFloat(data.step13__reassigned_device_impact)/100*ramp.yr1),4);
    const find_device_effort_proposed_yr1= round(find_device_effort_current_yr1-(find_device_effort_current_yr1*parseFloat(data.step13__find_device_impact)/100*ramp.yr1),4);
    const tickets_device_effort_proposed_yr1= round(tickets_device_effort_current_yr1-(tickets_device_effort_current_yr1*parseFloat(data.step13__ticket_device_impact)/100*ramp.yr1),4);
    const recalled_device_effort_proposed_yr1= round(recalled_device_effort_current_yr1-(recalled_device_effort_current_yr1*parseFloat(data.step13__recalled_device_impact)/100*ramp.yr1),4);
    const reconcile_device_effort_proposed_yr1= round(reconcile_device_effort_current_yr1-(reconcile_device_effort_current_yr1*parseFloat(data.step13__reconcile_device_impact)/100*ramp.yr1),4);


   const proposed_yr1 = Math.ceil(add_device_effort_proposed_yr1*get_salary(data.step13__add_device_job_class)
    + update_device_effort_proposed_yr1* get_salary(data.step13__update_device_job_class)
    + delete_device_effort_proposed_yr1*get_salary(data.step13__delete_device_job_class)
    + reassigned_device_effort_proposed_yr1*get_salary(data.step13__reassigned_device_job_class)
    + find_device_effort_proposed_yr1*get_salary(data.step13__find_device_job_class)
    + tickets_device_effort_proposed_yr1*get_salary(data.step13__ticket_device_job_class)
    + recalled_device_effort_proposed_yr1*get_salary(data.step13__recalled_device_job_class)
    + reconcile_device_effort_proposed_yr1*get_salary(data.step13__reconcile_device_job_class), 0);

    const add_device_effort_proposed_yr2= round(add_device_effort_current_yr2-(add_device_effort_current_yr2*parseFloat(data.step13__add_device_impact)/100*ramp.yr2),4);
    const update_device_effort_proposed_yr2= round(update_device_effort_current_yr2-(update_device_effort_current_yr2*parseFloat(data.step13__update_device_impact)/100*ramp.yr2),4);
    const delete_device_effort_proposed_yr2= round(delete_device_effort_current_yr2-(delete_device_effort_current_yr2*parseFloat(data.step13__delete_device_impact)/100*ramp.yr2),4);
    const reassigned_device_effort_proposed_yr2= round(reassigned_device_effort_current_yr2-(reassigned_device_effort_current_yr2*parseFloat(data.step13__reassigned_device_impact)/100*ramp.yr2),4);
    const find_device_effort_proposed_yr2= round(find_device_effort_current_yr2-(find_device_effort_current_yr2*parseFloat(data.step13__find_device_impact)/100*ramp.yr2),4);
    const tickets_device_effort_proposed_yr2= round(tickets_device_effort_current_yr2-(tickets_device_effort_current_yr2*parseFloat(data.step13__ticket_device_impact)/100*ramp.yr2),4);
    const recalled_device_effort_proposed_yr2= round(recalled_device_effort_current_yr2-(recalled_device_effort_current_yr2*parseFloat(data.step13__recalled_device_impact)/100*ramp.yr2),4);
    const reconcile_device_effort_proposed_yr2= round(reconcile_device_effort_current_yr2-(reconcile_device_effort_current_yr2*parseFloat(data.step13__reconcile_device_impact)/100*ramp.yr2),4);


    const proposed_yr2 = Math.ceil(add_device_effort_proposed_yr2*get_salary(data.step13__add_device_job_class)
    + update_device_effort_proposed_yr2* get_salary(data.step13__update_device_job_class)
    + delete_device_effort_proposed_yr2*get_salary(data.step13__delete_device_job_class)
    + reassigned_device_effort_proposed_yr2*get_salary(data.step13__reassigned_device_job_class)
    + find_device_effort_proposed_yr2*get_salary(data.step13__find_device_job_class)
    + tickets_device_effort_proposed_yr2*get_salary(data.step13__ticket_device_job_class)
    + recalled_device_effort_proposed_yr2*get_salary(data.step13__recalled_device_job_class)
    + reconcile_device_effort_proposed_yr2*get_salary(data.step13__reconcile_device_job_class), 0);

    const add_device_effort_proposed_yr3= round(add_device_effort_current_yr3-(add_device_effort_current_yr3*parseFloat(data.step13__add_device_impact)/100*ramp.yr3),4);
    const update_device_effort_proposed_yr3= round(update_device_effort_current_yr3-(update_device_effort_current_yr3*parseFloat(data.step13__update_device_impact)/100*ramp.yr3),4);
    const delete_device_effort_proposed_yr3= round(delete_device_effort_current_yr3-(delete_device_effort_current_yr3*parseFloat(data.step13__delete_device_impact)/100*ramp.yr3),4);
    const reassigned_device_effort_proposed_yr3= round(reassigned_device_effort_current_yr3-(reassigned_device_effort_current_yr3*parseFloat(data.step13__reassigned_device_impact)/100*ramp.yr3),4);
    const find_device_effort_proposed_yr3= round(find_device_effort_current_yr3-(find_device_effort_current_yr3*parseFloat(data.step13__find_device_impact)/100*ramp.yr3),4);
    const tickets_device_effort_proposed_yr3= round(tickets_device_effort_current_yr3-(tickets_device_effort_current_yr3*parseFloat(data.step13__ticket_device_impact)/100*ramp.yr3),4);
    const recalled_device_effort_proposed_yr3= round(recalled_device_effort_current_yr3-(recalled_device_effort_current_yr3*parseFloat(data.step13__recalled_device_impact)/100*ramp.yr3),4);
    const reconcile_device_effort_proposed_yr3= round(reconcile_device_effort_current_yr3-(reconcile_device_effort_current_yr3*parseFloat(data.step13__reconcile_device_impact)/100*ramp.yr3),4);


    const proposed_yr3 = Math.ceil(add_device_effort_proposed_yr3*get_salary(data.step13__add_device_job_class)
    + update_device_effort_proposed_yr3* get_salary(data.step13__update_device_job_class)
    + delete_device_effort_proposed_yr3*get_salary(data.step13__delete_device_job_class)
    + reassigned_device_effort_proposed_yr3*get_salary(data.step13__reassigned_device_job_class)
    + find_device_effort_proposed_yr3*get_salary(data.step13__find_device_job_class)
    + tickets_device_effort_proposed_yr3*get_salary(data.step13__ticket_device_job_class)
    + recalled_device_effort_proposed_yr3*get_salary(data.step13__recalled_device_job_class)
    + reconcile_device_effort_proposed_yr3*get_salary(data.step13__reconcile_device_job_class), 0);

    const add_device_effort_proposed_yr4= round(add_device_effort_current_yr4-(add_device_effort_current_yr4*parseFloat(data.step13__add_device_impact)/100*ramp.yr4),4);
    const update_device_effort_proposed_yr4= round(update_device_effort_current_yr4-(update_device_effort_current_yr4*parseFloat(data.step13__update_device_impact)/100*ramp.yr4),4);
    const delete_device_effort_proposed_yr4= round(delete_device_effort_current_yr4-(delete_device_effort_current_yr4*parseFloat(data.step13__delete_device_impact)/100*ramp.yr4),4);
    const reassigned_device_effort_proposed_yr4= round(reassigned_device_effort_current_yr4-(reassigned_device_effort_current_yr4*parseFloat(data.step13__reassigned_device_impact)/100*ramp.yr4),4);
    const find_device_effort_proposed_yr4= round(find_device_effort_current_yr4-(find_device_effort_current_yr4*parseFloat(data.step13__find_device_impact)/100*ramp.yr4),4);
    const tickets_device_effort_proposed_yr4= round(tickets_device_effort_current_yr4-(tickets_device_effort_current_yr4*parseFloat(data.step13__ticket_device_impact)/100*ramp.yr4),4);
    const recalled_device_effort_proposed_yr4= round(recalled_device_effort_current_yr4-(recalled_device_effort_current_yr4*parseFloat(data.step13__recalled_device_impact)/100*ramp.yr4),14);
    const reconcile_device_effort_proposed_yr4= round(reconcile_device_effort_current_yr4-(reconcile_device_effort_current_yr4*parseFloat(data.step13__reconcile_device_impact)/100*ramp.yr4),4);


    const proposed_yr4 = Math.ceil(add_device_effort_proposed_yr4*get_salary(data.step13__add_device_job_class)
    + update_device_effort_proposed_yr4* get_salary(data.step13__update_device_job_class)
    + delete_device_effort_proposed_yr4*get_salary(data.step13__delete_device_job_class)
    + reassigned_device_effort_proposed_yr4*get_salary(data.step13__reassigned_device_job_class)
    + find_device_effort_proposed_yr4*get_salary(data.step13__find_device_job_class)
    + tickets_device_effort_proposed_yr4*get_salary(data.step13__ticket_device_job_class)
    + recalled_device_effort_proposed_yr4*get_salary(data.step13__recalled_device_job_class)
    + reconcile_device_effort_proposed_yr4*get_salary(data.step13__reconcile_device_job_class), 0);

    const add_device_effort_proposed_yr5= round(add_device_effort_current_yr5-(add_device_effort_current_yr5*parseFloat(data.step13__add_device_impact)/100*ramp.yr5),4);
    const update_device_effort_proposed_yr5= round(update_device_effort_current_yr5-(update_device_effort_current_yr5*parseFloat(data.step13__update_device_impact)/100*ramp.yr5),4);
    const delete_device_effort_proposed_yr5= round(delete_device_effort_current_yr5-(delete_device_effort_current_yr5*parseFloat(data.step13__delete_device_impact)/100*ramp.yr5),4);
    const reassigned_device_effort_proposed_yr5= round(reassigned_device_effort_current_yr5-(reassigned_device_effort_current_yr5*parseFloat(data.step13__reassigned_device_impact)/100*ramp.yr5),4);
    const find_device_effort_proposed_yr5= round(find_device_effort_current_yr5-(find_device_effort_current_yr5*parseFloat(data.step13__find_device_impact)/100*ramp.yr5),4);
    const tickets_device_effort_proposed_yr5= round(tickets_device_effort_current_yr5-(tickets_device_effort_current_yr5*parseFloat(data.step13__ticket_device_impact)/100*ramp.yr5),4);
    const recalled_device_effort_proposed_yr5= round(recalled_device_effort_current_yr5-(recalled_device_effort_current_yr5*parseFloat(data.step13__recalled_device_impact)/100*ramp.yr5),4);
    const reconcile_device_effort_proposed_yr5= round(reconcile_device_effort_current_yr5-(reconcile_device_effort_current_yr5*parseFloat(data.step13__reconcile_device_impact)/100*ramp.yr5),4);


    const proposed_yr5 = Math.ceil(add_device_effort_proposed_yr5*get_salary(data.step13__add_device_job_class)
    + update_device_effort_proposed_yr5* get_salary(data.step13__update_device_job_class)
    + delete_device_effort_proposed_yr5*get_salary(data.step13__delete_device_job_class)
    + reassigned_device_effort_proposed_yr5*get_salary(data.step13__reassigned_device_job_class)
    + find_device_effort_proposed_yr5*get_salary(data.step13__find_device_job_class)
    + tickets_device_effort_proposed_yr5*get_salary(data.step13__ticket_device_job_class)
    + recalled_device_effort_proposed_yr5*get_salary(data.step13__recalled_device_job_class)
    + reconcile_device_effort_proposed_yr5*get_salary(data.step13__reconcile_device_job_class), 0);

    const saving_yr1=current_yr1-proposed_yr1;
    const saving_yr2=current_yr2-proposed_yr2;
    const saving_yr3=current_yr3-proposed_yr3;
    const saving_yr4=current_yr4-proposed_yr4;
    const saving_yr5=current_yr5-proposed_yr5;

    const step13__total = saving_yr1;

    const next = () => {
        props.next({ step13__total: step13__total, step13__yr1:saving_yr1, step13__yr2:saving_yr2 , step13__yr3:saving_yr3 , step13__yr4:saving_yr4 , step13__yr5:saving_yr5  });

    }
    const previous = () => {
        props.previous();
    }
    return (
        <div className='container'>
            <div className='row mt-3'>
            <div className='col-10' >
                <h4>Clinical Engineering Labor</h4>
                </div>
                <div className='col-2' >
                <SelectField
                    fieldColor="orange"
                    fieldName="step13__mvr"
                    value={(data && data["step13__mvr"] && props.mvrs.find(item => item.value === data["step13__mvr"])) || null}
                    options={props.mvrs}
                    label="Months to Value Realization"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step13__mvr"]}
                />
                </div>
            </div>
            <div className='col-12' >
                    <div className='row mt-3' style={{ textAlign: "center", background: parseFloat(step13__total) > 0 ? "#4D9262" : "#cc0000", color: "#ffff", padding: "10px", borderRadius: "5px" }}>
                        <div style={{ textTransform: "uppercase", fontSize: "0.65em" }}>Clinical Engineering Expected Savings (Year 1)</div>
                        <div className='row' >
                            
                            <div className='col-12'>
                                <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>
                                    ${formatCurrency(
                                        parseFloat(step13__total), 0)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className='row mt-3 gx-5 '>
                <div className='col-12'>
                <div className='row mt-3'>
                            <div className='col-12'>
                            <table className='table'>
                                 <colgroup>
                                    <col  style={{width:"40%"}} />
                                    <col style={{width:"15%"}} />
                                    <col style={{width:"25%"}} />
                                    <col style={{width:"10%"}} />
                                    <col style={{width:"10%"}} />
                                </colgroup>
                                                
                                    <tbody>
                                    <tr>
                                        <td valign="bottom">Manually add a device into fixed asset inventory, monitoring lists, maintenance management systems, etc.</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step13__add_device_effort"
                                                value={data["step13__add_device_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step13__add_device_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step13__add_device_job_class"
                                            value={(data && data["step13__add_device_job_class"] && job_classes.find(item => item.value === data["step13__add_device_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__add_device_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                            <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Volume (Year 1)</div>
                                            <div style={{ fontSize: "1.65em"}}>{formatCurrency(new_device_types_devices_yr1, 0)}</div>
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step13__add_device_impact"
                                            value={data["step13__add_device_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__add_device_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>
                                        

                                    <tr>
                                        <td valign="bottom">Manually update a device across fixed asset inventory, monitoring lists, maintenance management systems, etc.</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step13__update_device_effort"
                                                value={data["step13__update_device_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step13__update_device_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step13__update_device_job_class"
                                            value={(data && data["step13__update_device_job_class"] && job_classes.find(item => item.value === data["step13__update_device_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__update_device_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                            <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Volume (Year 1)</div>
                                            <div style={{ fontSize: "1.65em"}}>{formatCurrency(updated_devices_yr1, 0)}</div>
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step13__update_device_impact"
                                            value={data["step13__update_device_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__update_device_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td valign="bottom">Manually delete a device from fixed asset inventory, monitoring lists, maintenance management systems, etc.</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step13__delete_device_effort"
                                                value={data["step13__delete_device_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step13__delete_device_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step13__delete_device_job_class"
                                            value={(data && data["step13__delete_device_job_class"] && job_classes.find(item => item.value === data["step13__delete_device_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__delete_device_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                            <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Volume (Year 1)</div>
                                            <div style={{ fontSize: "1.65em"}}>{formatCurrency(decomissioned_devices_yr1, 0)}</div>
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step13__delete_device_impact"
                                            value={data["step13__delete_device_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__delete_device_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td valign="bottom">Manually reassign a device in fixed asset inventory, monitoring lists, maintenance management systems, etc.</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step13__reassigned_device_effort"
                                                value={data["step13__reassigned_device_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step13__reassigned_device_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step13__reassigned_device_job_class"
                                            value={(data && data["step13__reassigned_device_job_class"] && job_classes.find(item => item.value === data["step13__reassigned_device_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__reassigned_device_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                            <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Volume (Year 1)</div>
                                            <div style={{ fontSize: "1.65em"}}>{formatCurrency(reassigned_devices_yr1, 0)}</div>
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step13__reassigned_device_impact"
                                            value={data["step13__reassigned_device_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__reassigned_device_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td valign="bottom">Average time to manually find missing devices</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step13__find_device_effort"
                                                value={data["step13__find_device_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step13__find_device_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step13__find_device_job_class"
                                            value={(data && data["step13__find_device_job_class"] && job_classes.find(item => item.value === data["step13__find_device_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__find_device_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                            <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Volume (Year 1)</div>
                                            <div style={{ fontSize: "1.65em"}}>{formatCurrency(find_devices_yr1, 0)}</div>
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step13__find_device_impact"
                                            value={data["step13__find_device_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__find_device_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td valign="bottom">Average time to close a preventative maintenance ticket</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step13__ticket_device_effort"
                                                value={data["step13__ticket_device_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step13__ticket_device_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step13__ticket_device_job_class"
                                            value={(data && data["step13__ticket_device_job_class"] && job_classes.find(item => item.value === data["step13__ticket_device_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__ticket_device_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                            <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Volume (Year 1)</div>
                                            <div style={{ fontSize: "1.65em"}}>{formatCurrency(pm_tickets_devices_yr1, 0)}</div>
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step13__ticket_device_impact"
                                            value={data["step13__ticket_device_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__ticket_device_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td valign="bottom">Average time to process a manufacturer's recall</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step13__recalled_device_effort"
                                                value={data["step13__recalled_device_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step13__recalled_device_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step13__recalled_device_job_class"
                                            value={(data && data["step13__recalled_device_job_class"] && job_classes.find(item => item.value === data["step13__recalled_device_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__recalled_device_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                            <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Volume (Year 1)</div>
                                            <div style={{ fontSize: "1.65em"}}>{formatCurrency(recalled_devices_yr1, 0)}</div>
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step13__recalled_device_impact"
                                            value={data["step13__recalled_device_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__recalled_device_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="bottom">Average time to reconcile rental inventories, return and order (per month)</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step13__reconcile_device_effort"
                                                value={data["step13__reconcile_device_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step13__reconcile_device_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step13__reconcile_device_job_class"
                                            value={(data && data["step13__reconcile_device_job_class"] && job_classes.find(item => item.value === data["step13__reconcile_device_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__reconcile_device_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step13__reconcile_device_volume_yr1"
                                            value={data["step13__reconcile_device_volume_yr1"] + ""}
                                            label="Volume (Year 1)"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__reconcile_device_volume_yr1"]}
                                            pattern="[0-9]*"
                                        />
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step13__reconcile_device_impact"
                                            value={data["step13__reconcile_device_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step13__reconcile_device_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>               

                </div>

            </div>

            <div className='row mt-3 gx-5 '>
                <div className='col-12'>
                <div className='row mt-3'>
                            <div className='col-12'>
                            <table className='table'>  
                            <thead>
                                    <tr>
                                        <th scope="col">&nbsp;</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 1</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 2</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 3</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 4</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 5</th>
                                    </tr>
                                </thead>
                                    <tbody>
                                   
                                    <tr>
                                     
                                     <td>Current Spending Without Medigate</td>
                                     <td style={{ textAlign: "right" }}>${formatCurrency(current_yr1, 2)}</td>
                                     <td style={{ textAlign: "right" }}>${formatCurrency(current_yr2, 2)}</td>
                                     <td style={{ textAlign: "right" }}>${formatCurrency(current_yr3, 2)}</td>
                                     <td style={{ textAlign: "right" }}>${formatCurrency(current_yr4, 2)}</td>
                                     <td style={{ textAlign: "right" }}>${formatCurrency(current_yr5, 2)}</td>
                                 </tr>
                                 
                                 <tr>
                                   
                                     <td>Proposed Spending With Medigate</td>
                                     <td style={{ textAlign: "right" }}>${formatCurrency(proposed_yr1, 2)}</td>
                                     <td style={{ textAlign: "right" }}>${formatCurrency(proposed_yr2, 2)}</td>
                                     <td style={{ textAlign: "right" }}>${formatCurrency(proposed_yr3, 2)}</td>
                                     <td style={{ textAlign: "right" }}>${formatCurrency(proposed_yr4, 2)}</td>
                                     <td style={{ textAlign: "right" }}>${formatCurrency(proposed_yr5, 2)}</td>
                                 </tr>
                                
                                 <tr>
                               
                                     <td>Expected Savings With Medigate</td>
                                     <td style={{ textAlign: "right" }}>${formatCurrency(saving_yr1, 2)}</td>
                                     <td style={{ textAlign: "right" }}>${formatCurrency(saving_yr2, 2)}</td>
                                     <td style={{ textAlign: "right" }}>${formatCurrency(saving_yr3, 2)}</td>
                                     <td style={{ textAlign: "right" }}>${formatCurrency(saving_yr4, 2)}</td>
                                     <td style={{ textAlign: "right" }}>${formatCurrency(saving_yr5, 2)}</td>
                                 </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>               

                </div>
            </div>
            <div className='mt-3'>
                <div className="clearfix">
                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => previous()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                    <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => next()} ><i className="mdi mdi-skip-next " aria-hidden="true" ></i> Next</button>
                </div>
            </div>
        </div>
    )
}

export default ClinicalEngineering;